import { PaymentRequest, Stripe } from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment.prod';
import { PageQueryParams } from '../../../common/models/payment.model';

export function initStripePaymentRequest(
  params: PageQueryParams,
  stripe: Stripe
) {
  return new Observable<{
    paymentRequest: PaymentRequest;
  }>((subscriber) => {
    let paymentRequest = stripe.paymentRequest({
      country: 'NO',
      currency: 'nok',
      displayItems: [
        {
          amount: params.amount * 100,
          label: params.type,
        },
      ],
      total: {
        amount: params.amount * 100,
        label: environment.stripe.merchantName,
      },
      requestPayerName: false,
      requestPayerEmail: false,
    });

    subscriber.next({
      paymentRequest: paymentRequest,
    });

    subscriber.complete();
  });
}
