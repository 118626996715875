import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((_) => {
    // const script = document.createElement('script');
    // script.src = environment.dibs_script;
    // document.body.appendChild(script);
  })
  .catch((err) => console.error(err));
