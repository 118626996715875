import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeNo from '@angular/common/locales/nb';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {
  CalendarCommonModule,
  CalendarDayModule,
  CalendarMonthModule,
  CalendarWeekModule,
} from 'angular-calendar';
import { AppRoutingModule } from './app-routing.module';
import { AuthFormComponent } from './components/common/auth-form/auth-form.component';
import { CustomCalendarComponent } from './components/common/custom-calendar/custom-calendar.component';
import { FilterSectionComponent } from './components/common/filter-section/filter-section.component';
import { MobileSelectDoctorComponent } from './components/common/modals/mobile-select-doctor/mobile-select-doctor.component';
import { MobileSelectTimeslotComponent } from './components/common/modals/mobile-select-timeslot/mobile-select-timeslot.component';
import { SelectionSummaryComponent } from './components/common/selection-summary/selection-summary.component';
import { SpecialistCardComponent } from './components/common/specialist-card/specialist-card.component';
import { BookingComponent } from './components/pages/booking/booking.component';
import { ChangeComponent } from './components/pages/change/change.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { JitsiComponent } from './components/pages/jitsi/jitsi.component';
import { PaymentMethodPickerComponent } from './components/pages/payment-method-picker/payment-method-picker.component';
import { PaymentRedirectComponent } from './components/pages/payment-redirect/payment-redirect.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { SuccessComponent } from './components/pages/success/success.component';

registerLocaleData(localeNo);

@NgModule({
  declarations: [
    BookingComponent,
    CustomCalendarComponent,
    SpecialistCardComponent,
    FilterSectionComponent,
    CheckoutComponent,
    MobileSelectDoctorComponent,
    MobileSelectTimeslotComponent,
    AuthFormComponent,
    SelectionSummaryComponent,
    PaymentComponent,
    RegisterComponent,
    PaymentRedirectComponent,
    ErrorComponent,
    SuccessComponent,
    ChangeComponent,
    ResetPasswordComponent,
    JitsiComponent,
    PrivacyComponent,
    PaymentMethodPickerComponent,
  ],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    CalendarMonthModule,
    CalendarCommonModule,
    CalendarWeekModule,
    CalendarDayModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    SweetAlert2Module.forRoot(),
    NgSelectModule,
  ],
  exports: [],
  providers: [{ provide: LOCALE_ID, useValue: 'nb-NO' }],
})
export class HelsePrivatModule {}
