<div class="reset-password-container">
  <h1>Tilbakestille Passord</h1>
  <div class="reset-password-form" *ngIf="!loading">
    <form [formGroup]="form" (submit)="submitForm()">
      <div class="form-group" id="emailFormGroup">
        <label for="email"> Epost </label>
        <input id="email" name="email" type="text" formControlName="email"/>
        <div
          class="form-group__error"
          *ngIf="email.invalid && (email.dirty || email.touched)"
        >
          <div *ngIf="email.errors?.required">
            Epost er nødvendig
          </div>
          <div *ngIf="email.errors?.pattern">
            Skriv inn en gyldig epost
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="currentStep === 2">
        <label for="password"> Passord </label>
        <input id="password" name="password" type="password" formControlName="password"/>
        <div
          class="form-group__error"
          *ngIf="password?.invalid && (password?.dirty || password?.touched)"
        >
          <div *ngIf="password.errors?.required">
            Passord er påkrevd
          </div>

          <div *ngIf="password.errors?.minlength">
            Passordet må ha minst 8 tegn
          </div>
        </div>

        <div
          class="form-group__error"
          *ngIf="password?.value !== password_repeat?.value && (password?.dirty || password?.touched) && (password_repeat?.dirty || password_repeat?.touched)"
        >
          <div>
            Passordene er ikke like
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="currentStep === 2">
        <label for="repeatPassword"> Gjenta Passord </label>
        <input id="repeatPassword" name="password_repeat" type="password" formControlName="password_repeat"/>

        <div
          class="form-group__error"
          *ngIf="password_repeat?.invalid && (password_repeat?.dirty || password_repeat?.touched)"
        >
          <div *ngIf="password_repeat.errors?.required">
            Gjenta passordet
          </div>
          <div *ngIf="password_repeat.errors?.minlength">
            Passordet må ha minst 8 tegn
          </div>
        </div>
        <div
          class="form-group__error"
          *ngIf="password?.value !== password_repeat?.value && (password_repeat?.dirty || password_repeat?.touched) && (password?.dirty || password?.touched)"
        >
          <div>
            Passordene er ikke like
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <button type="submit">
          Sende Inn
        </button>
      </div>
    </form>
  </div>

  <div class="text-center my-4" *ngIf="loading">
    <span style="font-size: 32px;" class="fa fa-spin fa-spinner"></span>
  </div>
</div>
