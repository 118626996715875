import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormType } from '../../../common/models/common.models';
import { AppointmentsService } from '../../../common/services/appointments.service';

@Component({
  selector: 'my-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss'],
})
export class AuthFormComponent implements OnInit {
  @Input() type: FormType;
  @Input() authFormGroup: FormGroup;
  @Input() email: string;

  @Output() formSubmitted = new EventEmitter();
  @Output() resetPassword = new EventEmitter();

  authForm: FormGroup;
  formTypes = FormType;

  countries: any[] = [];

  userCountry: any;

  constructor(
    public formBuilder: FormBuilder,
    private appointmentsService: AppointmentsService
  ) {}

  ngOnInit(): void {
    if (this.authFormGroup) {
      this.authForm = this.authFormGroup;
      return;
    }

    this.userCountry = localStorage.getItem('country');

    this.authForm = this.formBuilder.group({
      email: [
        this.email,
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._+%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      // password: ['', [Validators.required]],
      // password_repeat: ['', [Validators.required]],
      // first_name: ['', [Validators.required]],
      // last_name: ['', [Validators.required]],
    });

    if (this.type !== FormType.Exist) {
      this.addRequiredFormControl('password');
      this.authForm
        .get('password')
        .setValidators([Validators.required, Validators.minLength(8)]);
    }

    if (this.type === FormType.Register) {
      this.addRequiredFormControl('password_repeat');
      this.addRequiredFormControl('first_name');
      this.addRequiredFormControl('last_name');
      this.addRequiredFormControl('mobile_phone');
      this.authForm.addControl(
        'country_id',
        new FormControl(this.userCountry ? +this.userCountry : null, [
          Validators.required,
        ])
      );
      this.authForm.addControl('newsletter_check', new FormControl(false));
      this.authForm.addControl('accept_terms', new FormControl(false));

      this.authForm
        .get('mobile_phone')
        .setValidators([
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
        ]);

      this.authForm
        .get('password_repeat')
        .setValidators([Validators.required, Validators.minLength(8)]);
    }

    this.getCountries();
  }

  get emailControl() {
    return this.authForm.get('email');
  }
  get password() {
    return this.authForm.get('password');
  }
  get password_repeat() {
    return this.authForm.get('password_repeat');
  }
  get first_name() {
    return this.authForm.get('first_name');
  }
  get last_name() {
    return this.authForm.get('last_name');
  }
  get mobile_phone() {
    return this.authForm.get('mobile_phone');
  }
  get country_id() {
    return this.authForm.get('country_id');
  }
  get newsletter_check() {
    return this.authForm.get('newsletter_check');
  }
  get accept_terms() {
    return this.authForm.get('accept_terms');
  }

  addRequiredFormControl(name: string) {
    this.authForm.addControl(name, new FormControl('', [Validators.required]));
  }

  confirmPassword(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('password_repeat').value) {
      return { invalid: true };
    }
  }

  getCountries() {
    this.appointmentsService.getCountries().subscribe((response) => {
      this.countries = response;
    });
  }

  /**
   * @desc Gets the button text based on component type value
   */
  btnLabelBasedOnType(): string {
    switch (this.type) {
      case this.formTypes.Login: {
        return 'Logg inn';
      }

      case this.formTypes.Register: {
        return 'Registrer';
      }

      default: {
        return 'Neste';
      }
    }
  }

  submitForm() {
    if (this.authForm.invalid) {
      Object.keys(this.authForm.controls).forEach((control) => {
        this.authForm.controls[control].markAsTouched();
      });

      return;
    }

    if (
      this.type === FormType.Register &&
      (this.password.value !== this.password_repeat.value ||
        !this.accept_terms.value)
    ) {
      this.authForm.controls['password'].markAsTouched();
      this.authForm.controls['password_repeat'].markAsTouched();
      return;
    }

    this.email = this.authForm.value.email;
    this.formSubmitted.emit({
      value: this.authForm.value,
      form: this.authForm,
    });
    if (!localStorage.getItem('country')) {
      localStorage.setItem('country', this.country_id.value);
    }
  }

  resetPasswordEvent() {
    this.resetPassword.emit();
  }
}
