<div
  class="selection-summary row pt-3 align-items-center d-flex"
  [ngClass]="{'bottom-success': !showBtn}"
>
  <div class="col-12 px-5 mb-4">
    <p class="section-description">
      Første ledige time med {{ category.category.name }} {{ specialist.user.first_name + ' ' + specialist.user.last_name }}
      er klokken {{ time }} den {{ date }}. Om du ønsker å bestille denne timen, trykker du på den grønne «Bestill»-knappen.
    </p>
  </div>
  <div class="col-lg-4 col-12">
    <div class="selection-summary-image">
      <img [src]="specialist.picture ? specialist.picture : baseUrl + 'media/default_m.png'"
           alt="doctor image"/>
    </div>
    <div class="doctor-info text-center mt-3">
      <div class="doctor-name fw-700">
        {{ specialist.user.first_name + ' ' + specialist.user.last_name }}
      </div>
      <div class="doctor-category">
        <select
          [(ngModel)]="selectedCategory"
          (change)="changedCategory()"
          *ngIf="canSelectCategory"
        >
          <option
            *ngFor="let cat of specialist.categories"
            [value]="cat.category.id"
          >{{ cat.category.name }}</option>
        </select>

        <span *ngIf="!canSelectCategory">
          {{ category.category.name }}
        </span>
      </div>
    </div>
  </div>

  <div class="col-lg-7 col-12 ml-lg-4 px-lg-0 px-5">
    <div class="appointment-info">
      <div class="appointment-info-item date">
        <div>
          <span class="iconify mr-2" data-icon="ant-design:calendar-twotone"></span>
          <label class="fw-700">Dato</label>
        </div>
        <span>{{ date }}</span>
      </div>
      <div class="appointment-info-item hour">
        <div>
          <span class="iconify mr-2" data-icon="ant-design:clock-circle-twotone"></span>
          <label class="fw-700">Tid</label>
        </div>
        <span>{{ time }}</span>
      </div>
      <div class="appointment-info-item price pt-3">
        <div>
          <span class="iconify mr-2" data-icon="ant-design:money-collect-twotone"></span>
          <label class="fw-700">Totalpris</label>
        </div>
        <span *ngIf="canSelectCategory">
          {{ getCategoryFromId(selectedCategory)?.price }} Kr
        </span>

        <span *ngIf="!canSelectCategory">
          {{ price }} Kr
        </span>
      </div>
    </div>

    <div
      class="selection-summary__next-step mt-5"
      *ngIf="showBtn"
    >
      <button
        type="button"
        class="btn main-button"
        (click)="nextStep()"
      >
        Bestill (1/3)
      </button>
    </div>
  </div>
</div>
