<div class="success-component">
  <div class="main-container">
    <div class="container">
      <div class="card-content" *ngIf="!loading && appointmentsService.appointment">
        <div class="thank-you">
          <h1>
            Takk skal du ha
          </h1>
        </div>
        <div class="verification-code">
          <p>
            Avtalen din med <strong>{{appointmentsService.appointment.doctorName}}</strong> ble opprettet vellykket
            <strong>{{appointmentsService.appointment.appointmentDate}} kl. {{appointmentsService.appointment.appointmentTime}}</strong>
          </p>
        </div>

        <div
          class="links-container"
        >
          <a (click)="router.navigateByUrl('/jitsi?code=' + appointmentsService.appointment.code)">
            Jisti Meet
          </a>
        </div>
      </div>

      <div class="loading-container" *ngIf="loading || !appointmentsService.appointment">
        <span class="fa fa-spin fa-spinner"></span>
      </div>
    </div>
  </div>
</div>
