import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';


@Injectable({
  providedIn: 'root'
})
export class IntrojsService {

  introJS = null;
  desktopOptions: any;
  mobileOptions: any;
  constructor() {
    this.desktopOptions = [
      {
        element: '.filter-section__category',
        intro: 'I kategorilisten kan du filtrere på ulike typer behandlere.',
        title: 'Filtrere'
      },
      {
        element: '.filter-section__name',
        intro: 'Med søket kan du søke direkte etter en bestemt behandler eller lege.',
        title: 'Filtrere'
      },
      {
        element: '.calendar-container',
        intro: 'Velg en dato for å se ledige timer på denne dagen og påfølgende dager.',
        title: 'Avtale dato'
      },
      {
        element: '.timeslots-list',
        intro: 'Velg et klokkeslett som passer deg den valgte dagen.',
        title: 'Avtale dato'
      },
      {
        element: '.cal-day-badge',
        intro: 'De grønne boblene viser deg hvor mange leger som har ledige timer denne dagen.',
        title: 'Avtale dato'
      },
      {
        element: '.selection-summary',
        intro: 'Dine nåværende valg viser deg første tilgjengelige legetime etter dine nåværende valg.<br/>Du kan endre dette ved å klikke på andre kategorier, datoer eller klokkeslett.<br/>Dobbeltsjekk at du har rett dato og klokkeslett.<br/>Om du er fornøyd og vil avtale time, klikker du på gå videre.',
        title: 'Nåværende valg'
      },
      {
        element: '#doctorCardsContainer',
        position: 'top',
        intro: 'Her ser du hvilke leger som er tilgjengelige ut fra dine nåværende valg.',
        title: 'Valg'
      }
    ];

    this.mobileOptions = [
      {
        element: '.filter-section__category',
        intro: 'I kategorilisten kan du filtrere på ulike typer behandlere.',
        title: 'Filtrere'
      },
      {
        element: '.filter-section__name',
        intro: 'Med søket kan du søke direkte etter en bestemt behandler eller lege.',
        title: 'Filtrere'
      },
      {
        element: '.calendar-container',
        intro: 'Velg en dato for å se ledige timer på denne dagen og påfølgende dager.',
        title: 'Avtale dato'
      },
      {
        element: '.mobile-change-time',
        intro: 'Velg et klokkeslett som passer deg den valgte dagen.',
        title: 'Avtale dato'
      },
      {
        element: '.cal-day-badge',
        intro: 'De grønne boblene viser deg hvor mange leger som har ledige timer denne dagen.',
        title: 'Avtale dato'
      },
      {
        element: '#mobileSelection .specialist-card',
        intro: 'Dine nåværende valg viser deg første tilgjengelige legetime etter dine nåværende valg.<br/>Du kan endre dette ved å klikke på andre kategorier, datoer eller klokkeslett.<br/>Dobbeltsjekk at du har rett dato og klokkeslett.<br/>Om du er fornøyd og vil avtale time, klikker du på gå videre.',
        title: 'Nåværende valg'
      },
      {
        element: '.change-doctor',
        intro: 'Her ser du hvilke leger som er tilgjengelige ut fra dine nåværende valg.',
        title: 'Valg'
      }
    ];
  }

  /**
   * Starts the introductive tutorial
   * @param help = true if the tutorial was manually started by clicking on the help button
   */
  startTutorial(help = false) {
    if (help || !localStorage.getItem('completed_tutorial')) {
      let options = {
        highlightClass: 'tutorialHighlight',
        tooltipClass: 'tutorialTooltip',
        scrollToElement: true,
        scrollTo: 'tooltip',
        // showProgress: true,
        nextLabel: '<i class="fas fa-chevron-right"></i>',
        prevLabel: '<i class="fas fa-chevron-left"></i>',
        steps: this.desktopOptions
      };

      if (window.screen.width < 768) {
        options.steps = this.mobileOptions;
      }

      setTimeout(() => {
        this.introJS = introJs();
        this.introJS.start();
        this.introJS
          .setOptions(options)
          .start();

        this.introJS.oncomplete(() => localStorage.setItem('completed_tutorial', 'true'));
      }, 0);
    }
  }
}
