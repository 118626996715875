import { HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserRole } from '../models/common.models';
// import {environment} from '../../../../../environments/environment.prod';
import {environment} from '../../../../../environments/environment';

export class OptionsProvider {
  public static readonly options = new HttpHeaderResponse({ headers: OptionsProvider.getCustomHttpHeader() });
  public static readonly guestOptions = new HttpHeaderResponse({ headers: OptionsProvider.getCustomGuestHttpHeader() });

  public static readonly user: any =
    (localStorage['token'] ? new JwtHelperService().decodeToken(localStorage['token']) : {});

  public static changeOptions() {
    Object.assign(OptionsProvider.options, new HttpHeaderResponse({ headers: OptionsProvider.getCustomHttpHeader() }));
    Object.assign(OptionsProvider.user, new JwtHelperService().decodeToken(localStorage['token']));
  }

  private static getCustomHttpHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage['token']}`,
      'Accept-Language': 'en'
    });
  }

  private static getCustomGuestHttpHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Guest-Secret': environment.guest_secret
    });
  }
}
