import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';
import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Doctor } from '../../../common/models/doctor.model';
import { AlertsService } from '../../../common/services/alerts.service';
import { AppointmentsService } from '../../../common/services/appointments.service';
import { ErrorHandlingService } from '../../../common/services/error-handling.service';
import { PaymentsService } from '../../../common/services/payments.service';
import { SpecialistsService } from '../../../common/services/specialists.service';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
})
export class ChangeComponent implements OnInit {
  calendarEvents: CalendarEvent[] = [];
  date: Date;
  appointmentDate: Date;
  doctor: Doctor;

  apiUrl = environment.base_url;

  code: string;
  rescheduleToken: string;
  cancelToken: string;

  showModal = false;
  errorMessage: string;

  appointment: any;
  timeslots: any[];

  selectedSlots: number[];

  updatingCalendar = false;
  loadingTimeslots = false;
  loadingDoctor = false;
  rescheduling = false;
  cancelling = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentsService: AppointmentsService,
    private specialistsService: SpecialistsService,
    private errorHandlingService: ErrorHandlingService,
    private alertsService: AlertsService,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.rescheduleToken = params.rescheduleToken;
      this.cancelToken = params.cancelToken;
      this.code = params.appointmentCode;

      // show spinners
      this.loadingTimeslots = true;
      this.loadingDoctor = true;
      this.updatingCalendar = true;

      this.appointmentsService
        .getAppointmentByToken(this.rescheduleToken)
        .pipe(
          switchMap((res) => {
            return this.appointmentsService.getAppointmentByToken(
              this.cancelToken
            );
          }),
          switchMap((res) => {
            return of(res);
          }),
          switchMap((res) => {
            if (res.timeslots) {
              this.appointment = res;
              if (this.appointment && this.appointment.timeslots) {
                this.doctor = this.appointment.timeslots[0].owner;
                this.date = moment(
                  this.appointment.timeslots[0].start
                ).toDate();
                this.appointmentDate = moment(
                  this.appointment.timeslots[0].start
                ).toDate();
              }

              this.loadingDoctor = false;
              return this.specialistsService.getTimeslotsForReschedule(
                res.timeslots[0].owner.user.id,
                {
                  start_date: moment(this.date).format('YYYY-MM-DD'),
                  end_date: moment(this.date)
                    .subtract(-1, 'days')
                    .format('YYYY-MM-DD'),
                  duration: res.timeslots[0].duration * res.timeslots.length,
                }
              );
            }
            return of(null);
          }),
          switchMap((res) => {
            this.timeslots = res.results;
            this.loadingTimeslots = false;
            return this.specialistsService.countTimeslots({
              start_date: moment(new Date()).format('YYYY-MM-DD'),
              end_date: moment(new Date())
                .add(3, 'months')
                .format('YYYY-MM-DD'),
              duration: 30,
              users: [this.appointment.timeslots[0].owner.user.id],
            });
          }),
          catchError((err) => {
            return throwError('Avtalen ble ikke funnet');
          })
        )
        .subscribe(
          (res) => {
            this.updateBadgeNumbers(res);
          },
          async (err) => {
            await this.errorHandlingService.showError(err);
            this.router.navigateByUrl('/');
          }
        );
    });
  }

  changedDate(event) {
    if (event) {
      this.date = event.date;
      this.loadingTimeslots = true;
      this.specialistsService
        .getTimeslotsForReschedule(
          this.appointment.timeslots[0].owner.user.id,
          {
            start_date: moment(this.date).format('YYYY-MM-DD'),
            end_date: moment(this.date)
              .subtract(-1, 'days')
              .format('YYYY-MM-DD'),
            duration:
              this.appointment.timeslots[0].duration *
              this.appointment.timeslots.length,
          }
        )
        .subscribe((res) => {
          this.timeslots = res.results;
          this.loadingTimeslots = false;
        });
    }
  }

  getCurrentSlot() {
    if (this.appointment) {
      return moment(this.appointment.timeslots[0].start).format('HH:mm');
    }

    return '';
  }

  displaySlot(slot) {
    return moment(slot.start).format('HH:mm');
  }

  selectedSlot(slot) {
    if (slot) {
      this.selectedSlots = slot.ids;
    } else {
      this.selectedSlots = undefined;
    }
  }

  updateBadgeNumbers(timeslotsCount) {
    this.calendarEvents = [];
    const results = timeslotsCount.results;
    Object.keys(results).forEach((date) => {
      const newEvent = {
        start: moment(date, 'YYYY-MM-DD').toDate(),
        title: 'Slot Name',
        allDay: true,
      };
      for (let i = 0; i < results[date]; ++i) {
        this.calendarEvents.push(newEvent);
      }
    });

    this.updatingCalendar = false;
  }

  reschedule() {
    if (this.selectedSlots) {
      this.rescheduling = true;
      this.appointmentsService
        .reschedule(this.rescheduleToken, this.selectedSlots)
        .subscribe(
          async (res) => {
            this.rescheduling = false;
            await this.alertsService.success('Avtalen ble omlagt');
            this.router.navigateByUrl('/');
          },
          (err) => {
            this.rescheduling = false;
            this.errorHandlingService.showError(
              err?.data?.error ? err?.data?.error : 'Noe gikk galt'
            );
          }
        );
    }
  }

  async cancel() {
    const alertResult = await this.alertsService.areYouSurePrompt(
      'Ønsker du å avbestille avtalen?'
    );
    if (alertResult.isConfirmed) {
      // the user confirmed to cancel the appointment
      this.cancelling = true;
      this.appointmentsService
        .cancel(this.cancelToken)
        .pipe(
          switchMap((res: any) => {
            if (
              this.appointment.payment_details &&
              this.appointment.payment_details.chargeId
            ) {
              return this.paymentsService.refundPayment();
            }
            return of([]);
          })
        )
        .subscribe(
          async (res) => {
            this.cancelling = false;
            await this.alertsService.success('Avtalen ble kansellert');
          },
          (err) => {
            this.cancelling = false;
            this.errorHandlingService.showError(
              err?.data?.error ? err?.data?.error : 'Noe gikk galt'
            );
          }
        );
    }
  }

  isAppointmentDay() {
    return (
      moment(this.date).format('D-M-Y') ===
      moment(this.appointmentDate).format('D-M-Y')
    );
  }
}
