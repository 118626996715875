import {CalendarNativeDateFormatter, DateFormatterParams, CalendarModule} from 'angular-calendar';
import {Injectable} from "@angular/core";

@Injectable()
export class CustomDateFormatter extends CalendarNativeDateFormatter {

  public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
    return new Intl.DateTimeFormat('no', {weekday: 'short'}).format(date);
  }

}
