import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../common/services/auth.service";
import {Location} from "@angular/common";

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-jitsi',
  templateUrl: './jitsi.component.html',
  styleUrls: ['./jitsi.component.scss']
})
export class JitsiComponent implements OnInit, AfterViewInit {
  @ViewChild('jitsiContainer', { static: true }) jitsiContainer: ElementRef;
  appointmentCode: string;

  domain: string = 'iotastic.iti.zone'; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params.code) {
          this.appointmentCode = params.code;
          this.options = {
            roomName: this.appointmentCode,
            width: '100%',
            height: '100%',
            parentNode: document.querySelector('#jitsi-iframe'),
            jwt: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozMiwiZW1haWwiOiJib2dkYW4udmlsY3VsZXNjdUBnbWFpbC5jb20iLCJ1c2VybmFtZSI6ImJvZ2Rhbi52aWxjdWxlc2N1IiwiZXhwIjoxNjQ4NTc0MjMzLCJjb250ZXh0Ijp7InVzZXIiOnsibmFtZSI6ImJvZ2Rhbi52aWxjdWxlc2N1IiwiZW1haWwiOiJib2dkYW4udmlsY3VsZXNjdUBnbWFpbC5jb20iLCJpZCI6MzIsImFmZmlsaWF0aW9uIjoib3duZXIifX0sImF1ZCI6ImppdHNpIiwiaXNzIjoiY2xpZW50Iiwicm9vbSI6IioifQ.8FYJg3z8VXjej03cB3egq2VxQayEbvf9su4EXRKbct0'
          };

          this.api = new JitsiMeetExternalAPI(this.domain, this.options);

          // Event handlers
          this.api.addEventListeners({
            readyToClose: this.handleClose,
            participantLeft: this.handleParticipantLeft,
            participantJoined: this.handleParticipantJoined,
            videoConferenceJoined: this.handleVideoConferenceJoined,
            videoConferenceLeft: this.handleVideoConferenceLeft,
            audioMuteStatusChanged: this.handleMuteStatus,
            videoMuteStatusChanged: this.handleVideoStatus,
          });
        }
      });
  }

  handleClose = () => {
    console.log('handleClose');
    this.location.back();
  };

  handleParticipantLeft = async (participant) => {
    console.log('handleParticipantLeft', participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    console.log('handleParticipantJoined', participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    console.log('handleVideoConferenceJoined', participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    console.log('handleVideoConferenceLeft');
  };

  handleMuteStatus = (audio) => {
    console.log('handleMuteStatus', audio); // { muted: true }
  };

  handleVideoStatus = (video) => {
    console.log('handleVideoStatus', video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  executeCommand(command: string) {
    this.api.executeCommand(command);
    if (command == 'hangup') {
      this.router.navigate(['/thank-you']);
      return;
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }

}
