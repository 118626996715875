import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { OptionsProvider as auth } from './options-provider';
import { Doctor } from '../models/doctor.model';
import { Observable, Subject } from 'rxjs';
import {ApiObject} from '../models/common.models';
import {UIHelper} from '../../components/common/helper/ui-helper';

@Injectable({
  providedIn: 'root',
})
export class SpecialistsService {
  private baseUrl = environment.base_url;
  private baseRoute = environment.base_route;

  specialists: Doctor[];
  specialistsNumber: number;

  constructor(private http: HttpClient) {}

  getSpecialistsAPI(obj?: ApiObject) {
    const url = UIHelper.formUrlBasedOnApiObject(
      `${this.baseUrl}${this.baseRoute}/doctors-nurses`,
      obj === undefined ? UIHelper.objToApi : obj
    );
    const country = localStorage.getItem('country');
    let countryUrl: any;
    if (country) {
      countryUrl = `${this.baseUrl}${this.baseRoute}/doctors-nurses?country=${country}`;
    } else {
      countryUrl = `${this.baseUrl}${this.baseRoute}/doctors-nurses`;
    }

    return this.http
      .get(
        countryUrl,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          this.specialists = response.results;
          this.specialistsNumber = response.count;

          return response;
        })
      );
  }

  getTimeslots(obj) {
    const url = UIHelper.formUrlBasedOnApiObject(
      `${this.baseUrl}${this.baseRoute}/doctors-nurses/slots`,
      obj
    );

    return this.http
      .get(
        url,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getTimeslotsByCategory(obj) {
    const url = UIHelper.formUrlBasedOnApiObject(
      `${this.baseUrl}${this.baseRoute}/doctors-nurses/slots-wo-duration`,
      obj
    );

    return this.http
      .get(
        url,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getDoctorsCategories() {
    return this.http
      .get(
        `${this.baseUrl}${this.baseRoute}/user/categories`,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  countTimeslots(obj) {
    const url = UIHelper.formUrlBasedOnApiObject(
      `${this.baseUrl}${this.baseRoute}/doctors-nurses/slots-count`,
      obj
    );

    return this.http
      .get(
        url,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getTimeslotsForReschedule(doctorId: number, obj) {
    const url = UIHelper.formUrlBasedOnApiObject(
      `${this.baseUrl}${this.baseRoute}/doctor-nurse/${doctorId}/slots`,
      obj
    );

    return this.http
      .get(
        url,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // getDoctorInfo(doctorId: number) {
  //   return this.http
  //     .get()
  // }
}
