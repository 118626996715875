<footer>
  <div class="footer-wrapper">
    <div class="row social">
      <div class="column-3">
        <div class="footer-logo">
          <div id="hs_cos_wrapper_site_logo" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-logo" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module">
            <span id="hs_cos_wrapper_site_logo_hs_logo_widget" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo" style="" data-hs-cos-general-type="widget" data-hs-cos-type="logo"><a href="//www.preact.no?hsLang=no" id="hs-link-site_logo_hs_logo_widget" style="border-width:0px;border:0px;"><img src="https://www.preact.no/hs-fs/hubfs/logo_negativ.png?width=2340&amp;name=logo_negativ.png" class="hs-image-widget " style="width:2340px;border-width:0px;border:0px;" width="2340" alt="logo_negativ" title="logo_negativ" srcset="https://www.preact.no/hs-fs/hubfs/logo_negativ.png?width=1170&amp;name=logo_negativ.png 1170w, https://www.preact.no/hs-fs/hubfs/logo_negativ.png?width=2340&amp;name=logo_negativ.png 2340w, https://www.preact.no/hs-fs/hubfs/logo_negativ.png?width=3510&amp;name=logo_negativ.png 3510w, https://www.preact.no/hs-fs/hubfs/logo_negativ.png?width=4680&amp;name=logo_negativ.png 4680w, https://www.preact.no/hs-fs/hubfs/logo_negativ.png?width=5850&amp;name=logo_negativ.png 5850w, https://www.preact.no/hs-fs/hubfs/logo_negativ.png?width=7020&amp;name=logo_negativ.png 7020w" sizes="(max-width: 2340px) 100vw, 2340px"></a></span>
          </div>
        </div>
      </div>
      <div class="column-9 right">
        <div id="hs_cos_wrapper_module_16376829071852" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module"><!-- module html  -->
          <div class="cp-social-icons" style="background-color: #422678">
            <a href="https://www.facebook.com/helseprivat" rel="noopener">
              <img src="https://www.preact.no/hubfs/Logos%20and%20icons/circle%20facebook%20icon%20white.svg">
            </a>
            <a href="https://www.linkedin.com/company/helse-privat/" target="_blank" rel="noopener">
              <img src="https://www.preact.no/hubfs/Logos%20and%20icons/circle%20linkedin%20icon%20white.svg">
            </a>
            <a href="https://www.instagram.com/helseprivat/" target="_blank" rel="noopener">
              <img src="https://www.preact.no/hubfs/Logos%20and%20icons/circle%20instagram%20icon%20white.svg">
            </a>
          </div></div>
      </div>
    </div>
    <div id="hs_cos_wrapper_footer-nav" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div class="row footer-nav-wrapper">
        <div class="column-3">
          <h6 style="color: #FFFFFF">Meny</h6>
          <ul class="footer-nav">
            <li class="">
              <a href="https://www.preact.no" style="color: #FFFFFF">
                Forsiden
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/behandlingstilbud?hsLang=no" style="color: #FFFFFF">
                Hva kan vi hjelpe deg med?
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/sporsm%C3%A5l-og-svar?hsLang=no" style="color: #FFFFFF">
                Spørsmål og svar
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/om-oss?hsLang=no" style="color: #FFFFFF">
                Om oss
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/kontakt_oss?hsLang=no" style="color: #FFFFFF">
                Kontakt oss
              </a>
            </li>
          </ul>
        </div>
      </div></div>
    <div class="row copyrights">
      <p></p><div id="hs_cos_wrapper_footer_copyrights" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footer_copyrights_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style="" data-hs-cos-general-type="widget" data-hs-cos-type="text">© 2022 Preact</span></div><p></p>
    </div>
  </div>
</footer>
