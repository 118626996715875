<div
  class="select-doctor-modal"
  *ngIf="display"
  (click)="closeModal()"
>
  <div class="select-doctor-modal__content" (click)="$event.stopImmediatePropagation()">
    <div class="select-doctor-modal__close text-right p-4">
      <div class="d-inline-block" (click)="closeModal()">
        <span
          class="iconify"
          data-icon="ant-design:close-circle-outlined"
        ></span>
      </div>
    </div>
    <div class="select-doctor-modal__header text-center p-4">
      <h4 class="fw-700 text-uppercase">Velg spesialist</h4>
    </div>

    <div class="select-doctor-modal__body row m-0">
      <div
        class="col-12 p-sm-5 specialist-card-col"
        *ngFor="let specialist of specialists; let i = index"
      >
        <my-specialist-card
          [doctor]="specialist"
          [selectedSlot]="timeslot"
          [mobile]="true"
          [sortedTimeslots]="sortedTimeslots"
          [selectedCategoryObj]="category"
          [showAll]="showAll"
          [buttonText]="i === 0 ? 'Valgt behandler' : 'Velg behandler'"
          [isSelectedSpecialist]="i === 0"
          (selectedSpecialist)="selectedSpecialist($event)"
          (selectedSpecialistByCategory)="selectedSpecialistByCategory($event)"
        ></my-specialist-card>
      </div>
    </div>
  </div>
</div>
