import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FormType } from '../../../common/models/common.models';
import { AppointmentsService } from '../../../common/services/appointments.service';
import { AuthService } from '../../../common/services/auth.service';
import { ErrorHandlingService } from '../../../common/services/error-handling.service';
import { PaymentsService } from '../../../common/services/payments.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  currentStep: number = 1;
  currentFormType: FormType = FormType.Exist;
  appointment: any;
  newAppointment: any;

  loadingForm = false;
  loadingPayment = false;
  email: string = '';
  currentFormGroup: FormGroup;

  constructor(
    private appointmentsService: AppointmentsService,
    private paymentsService: PaymentsService,
    private authService: AuthService,
    public formBuilder: FormBuilder,
    public router: Router,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.appointment = JSON.parse(localStorage.getItem('appt_info'));
    this.newAppointment = JSON.parse(localStorage.getItem('new_appt'));

    const token = localStorage.getItem('token');
    if (token) {
      this.loadingForm = true;
      this.authService.getLoggedInUserData().subscribe(
        (res) => {
          this.currentStep++;
          this.loadingForm = false;
        },
        (err) => {
          localStorage.removeItem('token');
          this.loadingForm = false;
        }
      );
    }
  }

  submitForm(event) {}

  /**
   * @desc Correctly format the start date of a time slot. Used in the html
   * @param slot
   * @param format
   */
  displaySlot(slot, format = 'HH:mm') {
    if (slot === undefined) {
      return moment(new Date()).format(format);
    }

    return moment(slot.start).format(format);
  }

  back() {
    if (this.currentFormType !== FormType.Exist) {
      this.currentFormType = FormType.Exist;
    } else {
      this.appointmentsService
        .unlockTimeslot(this.newAppointment.timeslot_ids)
        .subscribe(
          (res) => {
            this.router.navigateByUrl('/booking');
          },
          (err) => {
            this.errorHandlingService.showError(err);
          }
        );
    }
  }

  formSubmitted(event) {
    if (event) {
      switch (this.currentFormType) {
        case FormType.Exist: {
          // check if user exists
          this.loadingForm = true;
          this.authService.doesUserExist(event.value.email).subscribe((res) => {
            this.email = event.value.email;
            this.currentFormGroup = undefined;
            if (res.exists === true) {
              // the user already exists, prompt them to login
              this.currentFormType = FormType.Login;
            } else {
              // the user doesn't exist, prompt them to register
              this.currentFormType = FormType.Register;
            }
            this.loadingForm = false;
          });
          break;
        }

        case FormType.Login: {
          // login the user
          this.loadingForm = true;
          this.logUserIn(event);
          break;
        }

        case FormType.Register: {
          // register the user
          this.loadingForm = true;
          this.authService.register(event.value).subscribe(
            (res) => {
              this.logUserIn(event);
            },
            (err) => {
              this.errorHandlingService.showError(err);
              this.loadingForm = false;
              this.currentFormGroup = event.form;
            }
          );
          break;
        }
      }
    }
  }

  logUserIn(obj) {
    this.authService
      .login({ username: obj.value.email, password: obj.value.password })
      .subscribe(
        (res) => {
          this.authService.getLoggedInUserData().subscribe(
            (userData) => {
              this.currentFormGroup = undefined;

              localStorage.setItem('patient_id', userData.id.toString());
              localStorage.setItem(
                'patient_info',
                JSON.stringify(userData.user)
              );

              this.currentStep++;
              this.loadingForm = false;
            },
            (err) => {
              this.errorHandlingService.showError(err);
              this.loadingForm = false;
            }
          );
        },
        (err) => {
          this.errorHandlingService.showError(err);
          this.currentFormGroup = obj.form;
          this.loadingForm = false;
        }
      );
  }

  saveInStorage() {
    localStorage.setItem('amount', this.appointment.price.toString());
    localStorage.setItem(
      'doctor_id',
      this.appointment.specialist.user.id.toString()
    );
    localStorage.setItem(
      'doctor_info',
      JSON.stringify(this.appointment.specialist)
    );
    localStorage.setItem(
      'timeslot_ids',
      JSON.stringify(this.newAppointment.timeslot_ids)
    );
    localStorage.setItem('start_date', this.appointment.timeslot.start);
    localStorage.setItem(
      'category_setting_id',
      this.newAppointment.category_setting_id.toString()
    );
  }

  dibsPayment() {
    this.loadingPayment = true;
    this.saveInStorage();

    const patientInfo = JSON.parse(localStorage.getItem('patient_info'));
    const paymentOptions = {
      amount: this.appointment.price,
      date: moment(this.appointment.timeslot.start).format('YYYY-MM-DD'),
      // patientFirstName: patientInfo.first_name,
      // patientLastName: patientInfo.last_name,
      // patientEmail: patientInfo.email
    };

    this.paymentsService.createPayment(paymentOptions).subscribe(
      (res) => {
        this.loadingPayment = false;
        this.router.navigateByUrl('/payment');
      },
      (err) => {
        this.errorHandlingService.showError(err);
        this.loadingForm = false;
      }
    );
  }

  resetPassword(event) {
    this.router.navigateByUrl('/reset-password');
  }
}
