export const environment = {
  production: true,
  base_url: 'https://iti-portal.preact.no/',
  base_route: 'rest/appointments/guest',
  guest_secret:
    '8L18600GBhyLUYSAqXBFp49fxxokrt9dC5q54DEmCNIT6setY4k/GIiyfohitqvI6mEQtTY6gyZJOwO2CDqOyw==',
  dibs_checkout_key: '02e6f32939494017bdc5743193b39882',
  dibs_script: 'https://checkout.dibspayment.eu/v1/checkout.js?v=1',
  stripe: {
    merchantName: 'Preact AS',
    priceId: 'price_1LxphVDh5YefepehqAW7tts8',
    publishableKey: 'pk_live_zHF5MYm35tP42bCx7oC8kVa000vhJoklHv',
  },
};
