<div class="appointments-change my-5 py-5">
  <div class="doctor-info row">
    <ng-container *ngIf="!loadingDoctor">
      <div class="col-12 col-sm-2 text-center">
        <img [src]="doctor?.picture ? apiUrl + 'media/' + doctor?.picture : apiUrl + 'media/default_m.png'" alt="photo">
      </div>
      <div class="details col-12 col-sm-10 row">
        <div class="person-info col-12 col-sm-6 text-sm-left text-center">
          <div>
            <p class="person-name">{{doctor?.user?.first_name}} {{doctor?.user?.last_name}}</p>
          </div>
          <div>
            {{ appointment && appointment.category_setting ? appointment?.category_setting?.category?.name : 'Psychiatrist' }}
          </div>
        </div>
        <div class="booking-price-container col-12 col-sm-6 text-sm-left text-center">
          <p class="booking-price">
            {{ appointment && appointment.category_setting ? appointment?.category_setting?.price : '100' }}Kr
            <!--          ${window.doctorSpecialty ? window.doctorSpecialty.price : '100'} Kr-->
          </p>
        </div>
      </div>
    </ng-container>

    <div
      class="d-flex justify-content-center align-items-center w-100 h-100"
      *ngIf="loadingDoctor"
    >
      <span class="fa fa-spin fa-spinner"></span>
    </div>
  </div>

  <div class="appointments-change__calendar-slots row">
    <div class="col-12 col-lg-5 col-md-6 p-0">
      <ng-container *ngIf="!updatingCalendar">
        <my-custom-calendar
          [date]="date"
          [events]="calendarEvents"
          (changedDate)="changedDate($event)"
        ></my-custom-calendar>
      </ng-container>

      <div class="d-flex justify-content-center align-items-center w-100 h-100" *ngIf="updatingCalendar">
        <span class="fa fa-spin fa-spinner"></span>
      </div>
    </div>

    <div class="col-12 col-lg-6 col-md-6 mt-4 mt-md-0 p-0">
      <div
        class="time-slots-list-container"
        *ngIf="!loadingTimeslots"
      >
        <h4>Velg tidspunkt</h4>
        <div
          class="current-timeslot"
          *ngIf="isAppointmentDay()"
        >
          <span>Dette er den valgte tidsluken</span>
          <div
            class="slot-container my-4"
            (click)="selectedSlot(undefined)"
            [ngClass]="{'active-slot': !selectedSlots}"
          >
            <span class="time-slot">{{ getCurrentSlot() }}</span>
          </div>
          <span>Hvis du vil velge en annen tidsluke, velger du den fra listen nedenfor:</span>
        </div>
        <div class="time-slots-list">
        <span
          *ngIf="!timeslots || timeslots.length === 0"
          class="no-time-slots red-btn"
        >Ingen ledige timer på denne datoen</span>

          <div
            class="slot-container"
            *ngFor="let slot of timeslots"
            (click)="selectedSlot(slot)"
            [ngClass]="{'active-slot': selectedSlots && selectedSlots[0] === slot.id}"
          >
          <span
            class="time-slot"
          >
            {{ displaySlot(slot) }}
          </span>
          </div>
        </div>
        <div class="loading d-none">
          <span class="fa fa-spin fa-spinner"></span>
        </div>
      </div>

      <div
        class="d-flex justify-content-center align-items-center w-100 h-100"
        *ngIf="loadingTimeslots"
      >
        <span class="fa fa-spin fa-spinner"></span>
      </div>
    </div>
  </div>

  <div class="buttons-container row">
    <div class="back-btn-container col-12 col-sm-6">
      <a href="/" class="details-back-btn red-btn w-100">
        <i class="fas fas-back fa-long-arrow-alt-left fa-lg"></i>
        <p class="text-btn">TILBAKE</p>
      </a>
    </div>

    <div class="btn-container col-12 col-sm-6">
      <button
        class="red-btn details-next-btn w-100"
        (click)="reschedule()"
        [disabled]="!selectedSlots"
      >
        <p class="text-btn">
          <span *ngIf="!rescheduling">OPPDATER</span>
          <span *ngIf="rescheduling" class="fa fa-spin fa-spinner"></span>
        </p>
        <i *ngIf="!rescheduling" class="fas fas2 fa-long-arrow-alt-right fa-lg"></i>
      </button>
      <p class="validation-text d-none">Velg spesialist og dato for avtale</p>
    </div>
  </div>

  <div class="cancel-container">
    <span class="text-center">Hvis du vil avbryte avtalen, velger du alternativet nedenfor</span>
    <button class="details-back-btn red-btn" (click)="cancel()">
      <p class="text-btn">
        <span *ngIf="!cancelling">AVBRYT</span>
        <span *ngIf="cancelling" class="fa fa-spin fa-spinner"></span>
      </p>
    </button>
  </div>
</div>
