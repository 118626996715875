import { Injectable } from '@angular/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor() { }

  async showError(error) {
    await Swal.fire({
      title: 'Feil',
      text: error,
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }
}
