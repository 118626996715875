import { Component, OnInit } from '@angular/core';
import {FormType} from "../../../common/models/common.models";
import {FormGroup} from "@angular/forms";
import {AuthService} from "../../../common/services/auth.service";
import {ErrorHandlingService} from "../../../common/services/error-handling.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  currentFormType = FormType.Register;
  email = '';
  currentFormGroup: FormGroup;

  loadingAuth = false;

  constructor(
    private authService: AuthService,
    private errorHandlingService: ErrorHandlingService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  /**
   * @desc Event triggered when the register form is submitted
   * @param event
   */
  formSubmitted(event) {
    this.loadingAuth = true;
    this.authService
      .register(event.value)
      .subscribe(res => {
        this.logUserIn(event);
      }, err => {
        this.errorHandlingService.showError(err);
        this.loadingAuth = false;
        this.currentFormGroup = event.form;
      });
  }

  /**
   * @desc Log user in
   * @param obj
   */
  logUserIn(obj) {
    this.authService
      .login({username: obj.value.email, password: obj.value.password})
      .subscribe(res => {
        this.authService
          .getLoggedInUserData()
          .subscribe(userData => {
            this.currentFormGroup = undefined;
            localStorage.setItem('patient_id', userData.id.toString());
            localStorage.setItem('patient_info', JSON.stringify(userData.user));
            this.router.navigateByUrl('/booking');
            this.loadingAuth = false;
          }, err => {
            this.errorHandlingService.showError(err);
            this.loadingAuth = false;
          });
      }, err => {
        this.errorHandlingService.showError(err);
        this.currentFormGroup = obj.form;
        this.loadingAuth = false;
      });
  }

  resetPassword(event) {
    this.router.navigateByUrl('/reset-password');
  }
}
