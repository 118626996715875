<div class="error-component">
  <div class="main-container">
    <div class="container">
      <div class="error-icon">
        <i class="far fa-times-circle"></i>
      </div>
      <div class="error-content">
        <h1>
          Noe gikk galt under opprettelsen av avtalen. Vennligst prøv igjen eller kontakt oss hvis problemet vedvarer
        </h1>
      </div>

      <div class="checkout-buttons-container">
        <button class="form-btn text-btn" type="submit" (click)="tryAgain()">
          Prøv igjen
        </button>
      </div>
    </div>
  </div>
</div>
