<div
  class="select-timeslot-modal"
  *ngIf="display"
  (click)="closeModal()"
>
  <div class="select-timeslot-modal__content" (click)="$event.stopImmediatePropagation()">
    <div class="select-timeslot-modal__close text-right p-4">
      <div class="d-inline-block" (click)="closeModal()">
        <span
          class="iconify"
          data-icon="ant-design:close-circle-outlined"
        ></span>
      </div>
    </div>
    <div class="select-timeslot-modal__header text-center p-4">
      <h4 class="fw-700 text-uppercase">Velg tid</h4>
    </div>

    <div class="select-timeslot-modal__body">
        <div class="timeslots-list row py-3 mb-3 mx-0">
          <div
            class="col-4 col-sm-3 d-flex justify-content-center"
            *ngFor="let slot of timeslots; let i = index;"
          >
            <div
              class="slot-container my-2 py-1 fw-700"
              [ngClass]="selectedTimeslot === i ? 'active-slot' : ''"
              (click)="selectTimeslot(i)"
            >
              <span class="">{{ displaySlot(slot) }}</span>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
