<div
  class="specialist-card my-4"
  [ngClass]="{
    'not-same-slot': !isSameAsSelected(),
    'same-not-selected': isSameAsSelected() && !isSelectedSpecialist
  }"
>
<!--  <div class="specialist-card-info__notice text-center w-100">-->
<!--    <span> Ikke tilgjengelig for valgt tid </span>-->
<!--  </div>-->

  <p class="section-description" *ngIf="showHint">
    Første ledige time med {{ category.category.name }} {{ doctor.user.first_name + ' ' + doctor.user.last_name }}
    er klokken {{ displaySlot(selectedSlot) }} den {{ displaySlot(selectedSlot, 'DD.MM.YYYY') }}. Om du ønsker å bestille denne timen, trykker du på den grønne «Bestill»-knappen.
  </p>

  <div class="row">
    <div class="col-3 d-flex justify-content-center flex-column ml-4 mt-4">
      <div class="specialist-card-image">
        <!--    <div [ngStyle]="{'background-image': 'url(' + doctor.picture + ')'}"></div>-->
        <img [src]="doctor.picture ? doctor.picture : baseUrl + 'media/default_m.png'" alt="doctor image"/>
      </div>
    </div>

    <div class="col-8 d-flex justify-content-center flex-column px-3">
      <div class="specialist-card-info text-right overflow-hidden pl-4">
        <span class="specialist-card-info__name text-right w-100">
          {{ doctor.user.first_name + ' ' + doctor.user.last_name }}
        </span>
        <div class="specialist-card-info__date  text-right w-100">
          <span class="iconify mr-2" data-icon="ant-design:calendar-twotone"></span>
          <span>{{ displaySlot() }}</span>
        </div>
        <div class="specialist-card-info__timeslot  text-right w-100">
          <span class="iconify mr-2" data-icon="ant-design:clock-circle-twotone"></span>
          <span>{{ displaySlot(currentSlot, 'HH:mm') }}</span>
        </div>
        <div
          class="specialist-card-info__timeslot  text-right w-100"
          *ngIf="mobile"
        >
          <span class="iconify mr-2" data-icon="ant-design:idcard-twotone"></span>
          <span>{{ category?.category?.name }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="specialist-card-categories mt-5">
    <ul>
      <li
        class="d-flex justify-content-between align-items-center"
        *ngFor="let cat of doctor.categories"
      >
        <span
          class="w-50"
        >
          <span
            class="name w-100 d-block"
            (click)="selectedCategory(cat)"
          >
            {{ cat.category.name }}
          </span>

          <span class="category-time">
            <span class="iconify mr-1" data-icon="ant-design:clock-circle-twotone"></span>
            {{ cat.timeslots && cat.timeslots.length > 0 ? displaySlot(cat?.timeslots[0][0], 'HH:mm') : '09:00' }}
          </span>
        </span>
        <span class="price w-50">
          {{ cat.price }}Kr
        </span>
      </li>
    </ul>
  </div>

  <div
    class="specialist-card-cta pb-4"
    *ngIf="showCta"
  >
    <button
      type="button"
      (click)="selectSpecialist()"
      class="btn main-button"
    >
      {{ buttonText }}
    </button>
  </div>

</div>
