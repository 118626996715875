import {
  Component,
  OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter,
  Input, ViewChild
} from '@angular/core';
import {UIHelper} from "../helper/ui-helper";
import {CalendarEvent, CalendarMonthViewDay, CalendarView} from "angular-calendar";
import * as moment from "moment";
import {EventAction, EventColor} from 'calendar-utils';

@Component({
  selector: 'my-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomCalendarComponent implements OnInit {

  view: CalendarView = CalendarView.Month;
  viewDate: Date;

  currentMonth = '';
  currentYear = '';

  selectedMonthViewDay: CalendarMonthViewDay;
  selectedOldViewDay: CalendarMonthViewDay;
  selectedDayViewDate: Date;

  selectedTimeSlot: any;
  selectedDays: any = [];
  selectedDate: Date;

  calendarBadges: any;
  currentInterval: any;

  updatedCalendarBadges = new EventEmitter();

  @Input() date: Date;
  @Input() events: CalendarEvent[];

  @Output() changedDate = new EventEmitter();

  @ViewChild('tooltipContainer') tooltipContainer;

  constructor() { }

  ngOnInit(): void {
    this.viewDate = this.date;
    this.currentMonth = UIHelper.getMonthName(this.viewDate);
    this.currentYear = this.viewDate.getFullYear().toString();

    this.currentInterval = setInterval(() => {
      this.calendarBadges = document.getElementsByClassName('cal-day-badge');
      this.updatedCalendarBadges.emit(this.calendarBadges);
    }, 500);

    this.updatedCalendarBadges.subscribe(_ => {
      if (this.calendarBadges && this.calendarBadges.length > 0 && this.currentInterval) {
        clearInterval(this.currentInterval);

        for (let i = 0; i < this.calendarBadges.length; ++i) {
          this.calendarBadges[i].addEventListener('mouseover', event => {
            const rect = event.target.getBoundingClientRect();

            this.tooltipContainer.nativeElement.classList.remove('d-none');
            this.tooltipContainer.nativeElement.textContent = `${this.calendarBadges[i].textContent} tilgjengelige leger`;

            let left = rect.left + 30;
            let top = rect.top - 20;
            if (window.screen.width < 768) {
              left = rect.left - 160;
              top = rect.top - 40;

              if (left < 0) {
                left = 50;
              }
            }

            this.tooltipContainer.nativeElement.style.top = `${top}px`;
            this.tooltipContainer.nativeElement.style.left = `${left}px`;
          });

          this.calendarBadges[i].addEventListener('mouseleave', event => {
            this.tooltipContainer.nativeElement.classList.add('d-none');
          });
        }
      }
    })
  }



  getMonth(viewDate) {
    this.currentMonth = UIHelper.getMonthName(viewDate);
    this.currentYear = viewDate.getFullYear().toString();
  }

  /**
   * @desc Function called before the calendar re-renders
   * @param body
   */
  beforeMonthViewRender({body}: { body: CalendarMonthViewDay[] }): void {
    body.forEach((day) => {
      if (moment(day.date).format('YYYY-MM-DD') === moment(this.date).format('YYYY-MM-DD')) {
        day.cssClass = 'cal-day-selected';
        this.selectedOldViewDay = day;
        this.selectedMonthViewDay = day;
      }
    });
  }

  /**
   * @desc When a new calendar day is clicked, update the calendar ui and the timeslot list
   * @param day
   */
  dayClicked(day: CalendarMonthViewDay): void {
    if (day.isPast === false) {
      this.selectedTimeSlot = undefined;
      if (this.selectedMonthViewDay) {
        this.selectedOldViewDay = this.selectedMonthViewDay;
        this.selectedMonthViewDay = day;
      } else {
        this.selectedMonthViewDay = day;
      }
      const selectedDateTime = this.selectedMonthViewDay.date.getTime();
      const dateIndex = this.selectedDays.findIndex(
        (selectedDay) => selectedDay.date.getTime() === selectedDateTime
      );


      if (this.selectedOldViewDay) {
        delete this.selectedOldViewDay.cssClass;
        this.selectedDays = this.selectedDays.filter(item => item !== this.selectedOldViewDay);
        this.selectedDays.push(this.selectedMonthViewDay);
        day.cssClass = 'cal-day-selected';
        this.selectedMonthViewDay = day;
      } else {
        this.selectedDays.push(this.selectedMonthViewDay);
        day.cssClass = 'cal-day-selected';
        this.selectedMonthViewDay = day;
      }

      this.changedDate.emit(day);
    }
  }

  isCurrentMonth() {
    return moment(this.viewDate).month() === moment(new Date()).month();
  }

}
