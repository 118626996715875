import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'my-selection-summary',
  templateUrl: './selection-summary.component.html',
  styleUrls: ['./selection-summary.component.scss']
})
export class SelectionSummaryComponent implements OnInit, OnChanges {

  @Input() specialist: any;
  @Input() category: any;
  @Input() date: string;
  @Input() time: string;
  @Input() price: number;
  @Input() showBtn?: boolean = true;
  @Input() canSelectCategory?: boolean = true;

  @Output() btnEvent = new EventEmitter();
  @Output() updatedCategory = new EventEmitter();

  selectedCategory: number;
  baseUrl = environment.base_url;

  constructor() { }

  ngOnInit(): void {
    this.selectedCategory = this.category.category.id;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('category')) {
      this.selectedCategory = this.category.category.id;
    }
  }

  nextStep() {
    this.btnEvent.emit();
  }

  changedCategory() {
    this.updatedCategory.emit(this.selectedCategory);
  }

  getCategoryFromId(id: string | number) {
    if (typeof id === 'string') {
      return this.specialist.categories.find(cat => cat.category.id === parseInt(id));
    }
    return this.specialist.categories.find(cat => cat.category.id === id);
  }

}
