import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AppointmentsService } from '../../../common/services/appointments.service';
import { PaymentsService } from '../../../common/services/payments.service';

@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.scss'],
})
export class PaymentRedirectComponent implements OnInit {
  constructor(
    private paymentsService: PaymentsService,
    private appointmentsService: AppointmentsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paymentsService
      .chargePayment()
      .pipe(take(1))
      .subscribe(
        (chargeRes) => {
          if (!this.paymentsService.isMobilePayment) {
            this.paymentsService.chargeId = chargeRes.chargeId;
            const paymentDetails = {
              paymentId: this.paymentsService.paymentId,
              chargeId: this.paymentsService.chargeId,
              amount: this.paymentsService.amount,
            };

            this.appointmentsService
              .createAppointment(paymentDetails)
              .pipe(take(1))
              .subscribe(
                (res) => {
                  this.appointmentsService.appointmentId = res.id;
                  this.router.navigateByUrl('/success');
                },
                (error) => {
                  // if the appointment was not created successfully, refund the payment
                  this.paymentsService
                    .refundPayment()
                    .pipe(take(1))
                    .subscribe((res) => {
                      this.router.navigateByUrl('/error');
                    });
                }
              );
          }
        },
        (error) => {
          if (!this.paymentsService.isMobilePayment) {
            this.router.navigateByUrl('/error');
          }
        }
      );
  }
}
