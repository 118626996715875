import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../common/services/auth.service";
import {ErrorHandlingService} from "../../../common/services/error-handling.service";
import {AlertsService} from "../../../common/services/alerts.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  currentStep = 1;
  form: FormGroup;
  loading = false;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private errorHandlingService: ErrorHandlingService,
    private alertsService: AlertsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._+%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')
        ]
      ],
    });

    this.route
      .queryParams
      .subscribe(params => {
        if (params.token && params.email) {
          // the user needs to reset their password, so it's step 2
          this.currentStep = 2;
          this.token = params.token;
          this.addRequiredFormControl('password');
          this.addRequiredFormControl('password_repeat');

          this.email.setValue(params.email);
          this.email.disable();

          // validate the token and, if valid, show the form, otherwise show error
          this.loading = true;
          this.authService
            .validateToken(params.email, params.token)
            .subscribe(
              _ => this.loading = false,
              async err => {
                this.loading = false;
                await this.errorHandlingService.showError('Token är inte giltig');
                this.router.navigateByUrl('/');
              }
            )
        }
      });
  }

  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }
  get password_repeat() { return this.form.get('password_repeat'); }

  addRequiredFormControl(name: string) {
    this.form.addControl(
      name,
      new FormControl('', [Validators.required, Validators.minLength(8)])
    );
  }

  confirmPassword(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('password_repeat').value) {
      return { invalid: true };
    }
  }

  submitForm() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(control => {
        this.form.controls[control].markAsTouched();
      });

      return;
    }

    if (this.currentStep === 2 && this.password.value !== this.password_repeat.value) {
      this.form.controls['password'].markAsTouched();
      this.form.controls['password_repeat'].markAsTouched();
      return;
    }

    console.log('FORM SUBMITTED', this.form);
    this.loading = true;
    if (this.currentStep === 1) {
      this.authService
        .getEmailToken(this.email.value)
        .subscribe(
          async _ => {
            this.loading = false;
            await this.alertsService.success('En länk med ytterligare steg skickades till din e-postadress');
            this.router.navigateByUrl('/');
          },
          err => {
            this.loading = false;
            this.errorHandlingService.showError('Noe gikk galt');
          }
        );
    } else {
      this.authService
        .resetPassword(this.email.value, this.token, this.password.value)
        .subscribe(
          async _ => {
            this.loading = false;
            await this.alertsService.success('Ditt lösenord har ändrats');
            this.router.navigateByUrl('/');
          },
          err => {
            this.loading = false;
            this.errorHandlingService.showError('Ditt lösenord kunde inte återställas. Kontakta oss om problemet kvarstår');
          }
        )
    }
  }
}
