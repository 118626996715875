<div class="container py-5 mt-5" *ngIf="!loadingData">
<!--  <div class="d-flex justify-content-sm-end justify-content-center align-items-center">-->
<!--    <button-->
<!--      type="button"-->
<!--      class="help-button flex-sm-row flex-column justify-content-sm-start justify-content-center"-->
<!--      (click)="introService.startTutorial(true)"-->
<!--    >-->
<!--      <span>Trenger du hjelp til å bestille time? Klikk her</span>-->
<!--      <i class="fas fa-question-circle"></i>-->
<!--    </button>-->
<!--  </div>-->

  <section>
    <p class="section-description">
      Velg behandlingstype eller søk etter behandler.
    </p>
    <my-filter-section
      [categories]="categories"
      [categoryIndex]="selectedCategory"
      [specialists]="allSpecialists"
      [currentSearchTerm]="currentSearchTerm"
      (filteredCategory)="filterByCategory($event)"
      (startedSearch)="searchByName($event)"
    ></my-filter-section>
  </section>

  <div
    class="d-lg-block d-md-block d-none"
    *ngIf="selectedUserCategory"
  >
<!--    <p class="section-description">-->
<!--      Første ledige time med {{ selectedUserCategory.category.name }} {{ specialists[0].user.first_name + ' ' + specialists[0].user.last_name }}-->
<!--      er klokken {{ displaySlot(timeslots[selectedTimeslot]) }} den {{ displaySlot(timeslots[selectedTimeslot], 'DD.MM.YYYY') }}. Om du ønsker å bestille denne timen, trykker du på den grønne «Bestill»-knappen.-->
<!--    </p>-->
    <my-selection-summary
      [specialist]="specialists[0]"
      [category]="selectedUserCategory"
      [date]="displaySlot(timeslots[selectedTimeslot], 'DD.MM.YYYY')"
      [time]="displaySlot(timeslots[selectedTimeslot])"
      [price]="selectedUserCategory.price"
      [canSelectCategory]="false"
      (btnEvent)="nextStep()"
      (updatedCategory)="changedSelectedCategory($event)"
    >
    </my-selection-summary>

  </div>

    <div
      id="mobileSelection"
      class="card-container col-12 mb-5 d-md-none d-block"
      *ngIf="displayCards && specialists && specialists.length > 0"
    >
<!--      <p class="section-description">-->
<!--        Første ledige time med {{ selectedUserCategory.category.name }} {{ specialists[0].user.first_name + ' ' + specialists[0].user.last_name }}-->
<!--        er klokken {{ displaySlot(timeslots[selectedTimeslot]) }} den {{ displaySlot(timeslots[selectedTimeslot], 'DD.MM.YYYY') }}. Om du ønsker å bestille denne timen, trykker du på den grønne «Bestill»-knappen.-->
<!--      </p>-->
      <my-specialist-card
        [doctor]="specialists[0]"
        [mobile]="true"
        [showCta]="false"
        [category]="selectedUserCategory"
        [showAll]="searchedByName"
        [selectedCategoryObj]="categories[selectedCategory]"
        [selectedSlot]="timeslots[selectedTimeslot]"
        [sortedTimeslots]="sortedTimeslots"
        [showHint]="true"
        (selectedSpecialist)="selectedSpecialist($event)"
        (selectedSpecialistByCategory)="selectedSpecialistByCategory($event)"
      ></my-specialist-card>
    </div>

  <div
    *ngIf="selectedCategory !== -1 && categories[selectedCategory].is_prescription_category"
    class="is-prescription-cat mt-4"
  >
    Våre leger skriver ikke ut vanedannende medisiner som A-og B-preparater, legemidler som krever tett oppfølging av
    fastlege, eller legemidler som krever fysiske undersøkelser.
  </div>

  <div class="row">
    <h4 class="section-description pl-3 fw-600">
      Endre dato eller tidspunkt:
    </h4>
<!--    <h4 class="pl-3 fw-700 text-center">Endre dato eller tidspunkt:</h4>-->
    <div class="col-lg-4 col-md-6 col-12">
<!--      <h4 class="pl-3 fw-700 text-center">Velg dato</h4>-->
      <my-custom-calendar
        *ngIf="!updatingCalendar"
        [date]="getDateFromString(apiObj.start_date)"
        [events]="calendarEvents"
        (changedDate)="changedDate($event)"
      ></my-custom-calendar>

      <div class="d-flex justify-content-center mt-4">
        <div class="spinner-grow" role="status" *ngIf="updatingCalendar">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-md-6 pl-md-5" *ngIf="displayCards && timeslots && timeslots.length > 0 && specialists && specialists.length > 0">
<!--      <h4 class="fw-700 text-center">Velg tidspunkt</h4>-->
      <div class="timeslots-list row py-3 mb-3">
        <div class="col-xl-1 col-lg-2 col-md-3 col-sm-4 col-6" *ngFor="let slot of timeslots; let i = index;">
          <div
            class="slot-container my-2 py-1 fw-700"
            [ngClass]="selectedTimeslot === i ? 'active-slot' : ''"
            (click)="selectTimeslot(i)"
          >
            <span class="">{{ displaySlot(slot) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-md-6 d-md-block d-none text-center" *ngIf="!displayCards">
      <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <h4
      class="col-lg-8 col-md-6 mt-5 text-center"
      *ngIf="specialistsFound() && (!timeslots || timeslots.length === 0)"
    >
      Ingen spesialister matcher søkekriteriene dine
    </h4>
  </div>

<!--  <div class="d-md-none d-block" *ngIf="specialists && specialists.length > 0">-->
<!--    <div class="selection-summary mobile d-flex justify-content-between align-items-center mt-5">-->
<!--      <span class="mr-5">Avtale tid</span>-->
<!--      <span class="time">{{ displaySlot(timeslots[selectedTimeslot], 'DD.MM.YYYY HH:mm') }}</span>-->
<!--    </div>-->

<!--    <div class="mobile-change-time mt-3">-->
<!--      <button-->
<!--        type="button"-->
<!--        class="btn main-button"-->
<!--        (click)="selectTimeslotModal = true;"-->
<!--      >-->
<!--        Endre tid-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->

  <h4
    class="mt-5 text-center"
    *ngIf="!specialistsFound()"
  >
    Ingen spesialister matcher søkekriteriene dine
  </h4>

<!--  <div class="row my-4" *ngIf="displayCards && timeslots && timeslots.length > 0 && specialists && specialists.length > 0">-->
  <div id="doctorCardsContainer" class="row my-4" *ngIf="displayCards && specialists && specialists.length > 0">
<!--    <h4-->
<!--      class="mt-5 text-center d-md-flex d-none px-2 py-3"-->
<!--      *ngIf="specialistsFound() && timeslots && timeslots.length > 0"-->
<!--    >-->
<!--      <div class="d-flex justify-content-between w-100"><div>Det er behandlere som samsvarer med kriteriene dine. Velg en nedenfor</div><div><i class="fa fa-chevron-down"></i></div></div>-->
<!--    </h4>-->

      <div
        class="card-container col-lg-4 col-md-12 col-12 mb-5 d-md-block d-none"
        *ngFor="let specialist of specialists; let i = index"
      >
        <ng-container>
          <my-specialist-card
            [doctor]="specialist"
            [showAll]="searchedByName"
            [selectedCategoryObj]="categories[selectedCategory]"
            [selectedSlot]="timeslots[selectedTimeslot]"
            [sortedTimeslots]="sortedTimeslots"
            [buttonText]="i === 0 ? 'Valgt behandler' : 'Velg behandler'"
            [isSelectedSpecialist]="i === 0"
            (selectedSpecialist)="selectedSpecialist($event)"
            (selectedSpecialistByCategory)="selectedSpecialistByCategory($event)"
          ></my-specialist-card>
        </ng-container>
      </div>

<!--    <div-->
<!--      id="mobileSelection"-->
<!--      class="card-container col-12 mb-5 d-md-none d-block"-->
<!--    >-->
<!--      <my-specialist-card-->
<!--        [doctor]="specialists[0]"-->
<!--        [mobile]="true"-->
<!--        [showCta]="false"-->
<!--        [category]="selectedUserCategory"-->
<!--        [showAll]="searchedByName"-->
<!--        [selectedCategoryObj]="categories[selectedCategory]"-->
<!--        [selectedSlot]="timeslots[selectedTimeslot]"-->
<!--        [sortedTimeslots]="sortedTimeslots"-->
<!--        (selectedSpecialist)="selectedSpecialist($event)"-->
<!--        (selectedSpecialistByCategory)="selectedSpecialistByCategory($event)"-->
<!--      ></my-specialist-card>-->
<!--    </div>-->
  </div>
</div>

<div class="container py-5 text-center" *ngIf="loadingData || !displayCards">
  <div class="spinner-grow" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  class="mobile-cta-buttons d-md-none d-flex row"
  *ngIf="!loadingData && !selectDoctorModal && !selectTimeslotModal"
>
  <div class="change-doctor col-4 text-center p-0">
    <button
      type="button"
      class="btn main-button"
      (click)="selectDoctorModal = true;"
    >
      Bytte lege
    </button>
  </div>

  <div class="next-step col-8 text-center p-0">
    <button
      type="button"
      class="btn w-100"
      (click)="nextStep()"
    >
      Bestill (1/3)
    </button>
  </div>
</div>

<my-select-doctor-modal
  [display]="selectDoctorModal"
  [specialists]="specialists"
  [timeslot]="timeslots[selectedTimeslot]"
  [category]="categories[selectedCategory]"
  [sortedTimeslots]="sortedTimeslots"
  [showAll]="searchedByName"
  (applyChanges)="mobileDoctorSelected($event)"
  (applyChangesByCategory)="selectedSpecialistByCategory($event)"
></my-select-doctor-modal>

<my-select-timeslot-modal
  [display]="selectTimeslotModal"
  [timeslots]="timeslots"
  [selectedTimeslot]="selectedTimeslot"
  [displaySlot]="displaySlot"
  (applyChanges)="mobileTimeslotSelected($event)"
></my-select-timeslot-modal>
