import { Component, OnInit } from '@angular/core';
import {AppointmentsService} from "../../../common/services/appointments.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  loading = true;
  constructor(
    public appointmentsService: AppointmentsService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.appointmentsService.getAppointmentById().subscribe(_ => {
      this.loading = false;
    });
  }

}
