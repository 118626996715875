<div class="my-popup" *ngIf="{ params: params$ | async }; let vm">
  <div class="my-popup__content">
    <p class="title-content">
      {{ vm.params?.title }}
    </p>
    <div class="content-description">
      <span>1 {{ vm.params?.type }}</span>
      <span class="amount-span">{{ vm.params?.amount }} Kr</span>
    </div>
  </div>

  <div class="my-popup__buttons">
    <!-- <div id="apple-pay-button-container"></div> -->
    <button *ngIf="mountedButton" class="apple-pay" (click)="applePay()">
      <span class="iconify" data-icon="ant-design:apple-outlined"></span>
      <span>Pay</span>
    </button>

    <div class="loading-container mb-3" *ngIf="mountedButton === null">
      <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <button class="normal-payment" (click)="normalPayment()">
      <span *ngIf="!loadingNormalPayment">{{ vm.params?.payByCardLabel }}</span>
      <div *ngIf="loadingNormalPayment" class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </button>
  </div>
</div>
