import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import * as moment from "moment";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'my-specialist-card',
  templateUrl: './specialist-card.component.html',
  styleUrls: ['./specialist-card.component.scss']
})
export class SpecialistCardComponent implements OnInit, OnChanges {

  @Input() doctor: any;
  @Input() selectedSlot: any;
  @Input() showAll: boolean;
  @Input() selectedCategoryObj: any;
  @Input() sortedTimeslots: boolean;

  @Input() buttonText?: string = 'Velg behandler';
  @Input() showCta?: boolean = true;
  @Input() mobile?: boolean = false;
  @Input() category?: any = null;
  @Input() isSelectedSpecialist?: boolean = true;
  @Input() showHint?: boolean = false;

  @Output() selectedSpecialist = new EventEmitter();
  @Output() selectedSpecialistByCategory = new EventEmitter();

  baseUrl = environment.base_url;

  currentSlot: any;

  constructor() { }

  ngOnInit(): void {
    this.updateCurrentSlot();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sortedTimeslots) {
      this.updateCurrentSlot();
    }
  }

  updateCurrentSlot() {
    if (this.showAll) {
      this.currentSlot = this.doctor.timeslots[0][0];
    } else {
      const catIndex = this.doctor.categories.indexOf(
        this.doctor.categories.find(cat => cat.category.id === this.selectedCategoryObj.id)
      );
      // console.log('CURRENT SLOT', this.doctor, catIndex, this.selectedCategoryObj);
      this.currentSlot = this.doctor.categories[catIndex]?.timeslots[0][0];
    }
  }

  displaySlot(slot = this.currentSlot, format = 'DD.MM.YYYY') {
    return moment(slot.start).format(format);
  }

  selectSpecialist() {
    this.selectedSpecialist.emit(this.doctor);
  }

  isSameAsSelected() {
    if (this.selectedSlot && this.currentSlot) {
      return moment(this.currentSlot.start).isSame(moment(this.selectedSlot.start));
    }
    // console.log('UNDEFINED SLOT', this.selectedSlot, this.currentSlot, this.doctor);
    return false;
  }

  selectedCategory(category) {
    this.selectedSpecialistByCategory.emit({category, doctor: this.doctor});
  }
}
