export enum UserRole {
  Doctor = 'doctor',
  Admin = 'admin',
  Nurse = 'nurse',
  Patient = 'patient'
}

export enum OrderingChoice {
  Category_Name = 'name',
  User_Name = 'auth_user.first_name,auth_user.last_name',
  Timeslot = '-timeslots__start',
  Timeslot_For_User = 'first_slot'
}

export enum OrderByChoice {
  Timeslot = 'start'
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export interface ApiObject {
  offset?: number;
  limit?: number;
  user?: number | string;
  ordering?: string;
  code?: string;
  date?: string | null;
  search?: string;
  order_by?: string;
  order?: string;
  start_date?: string;
  end_date?: string;
}

export enum FormType {
  Exist = 'exist',
  Login = 'login',
  Register = 'register'
}
