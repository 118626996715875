<div class="auth-form my-5">
  <form [formGroup]="authForm" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="emailInput">Din epost</label>
      <input
        type="text"
        class="form-input"
        id="emailInput"
        name="email"
        placeholder="Din epost..."
        formControlName="email"
        [ngClass]="{
          error:
            emailControl.invalid && (emailControl.dirty || emailControl.touched)
        }"
      />
      <div
        class="form-group__error"
        *ngIf="
          emailControl.invalid && (emailControl.dirty || emailControl.touched)
        "
      >
        <div *ngIf="emailControl.errors?.required">Epost er nødvendig</div>
        <div *ngIf="emailControl.errors?.pattern">
          Skriv inn en gyldig epost
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="type !== formTypes.Exist">
      <label for="passwordInput">Passord</label>
      <input
        type="password"
        class="form-input"
        name="password"
        id="passwordInput"
        placeholder="Passord..."
        formControlName="password"
        [ngClass]="{
          error:
            (password?.invalid || password?.value !== password_repeat?.value) &&
            (password?.dirty || password?.touched) &&
            (password_repeat?.dirty || password_repeat?.touched)
        }"
      />
      <div
        class="form-group__error"
        *ngIf="password?.invalid && (password?.dirty || password?.touched)"
      >
        <div *ngIf="password.errors?.required">Passord er påkrevd</div>

        <div *ngIf="password.errors?.minlength">
          Passordet må ha minst 8 tegn
        </div>
      </div>

      <div
        class="form-group__error"
        *ngIf="
          password?.value !== password_repeat?.value &&
          (password?.dirty || password?.touched) &&
          (password_repeat?.dirty || password_repeat?.touched)
        "
      >
        <div>Passordene er ikke like</div>
      </div>
    </div>

    <div class="form-group" *ngIf="type === formTypes.Register">
      <label for="repeatPasswordInput">Gjenta passord</label>
      <input
        type="password"
        class="form-input"
        id="repeatPasswordInput"
        name="password_repeat"
        placeholder="Gjenta passord..."
        formControlName="password_repeat"
        [ngClass]="{
          error:
            (password_repeat?.invalid ||
              password?.value !== password_repeat?.value) &&
            (password_repeat?.dirty || password_repeat?.touched) &&
            (password_repeat?.dirty || password_repeat?.touched)
        }"
      />
      <div
        class="form-group__error"
        *ngIf="
          password_repeat?.invalid &&
          (password_repeat?.dirty || password_repeat?.touched)
        "
      >
        <div *ngIf="password_repeat.errors?.required">Gjenta passordet</div>
        <div *ngIf="password_repeat.errors?.minlength">
          Passordet må ha minst 8 tegn
        </div>
      </div>
      <div
        class="form-group__error"
        *ngIf="
          password?.value !== password_repeat?.value &&
          (password_repeat?.dirty || password_repeat?.touched) &&
          (password?.dirty || password?.touched)
        "
      >
        <div>Passordene er ikke like</div>
      </div>
    </div>

    <div class="form-group" *ngIf="type === formTypes.Register">
      <label for="firstNameInput">Fornavn</label>
      <input
        type="text"
        class="form-input"
        id="firstNameInput"
        name="first_name"
        placeholder="Fornavn..."
        formControlName="first_name"
        [ngClass]="{
          error: first_name.invalid && (first_name.dirty || first_name.touched)
        }"
      />
      <div
        class="form-group__error"
        *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)"
      >
        <div *ngIf="first_name.errors?.required">Fornavn kreves</div>
      </div>
    </div>

    <div class="form-group" *ngIf="type === formTypes.Register">
      <label for="lastNameInput">Etternavn</label>
      <input
        type="text"
        class="form-input"
        id="lastNameInput"
        name="last_name"
        placeholder="Etternavn..."
        formControlName="last_name"
        [ngClass]="{
          error: last_name.invalid && (last_name.dirty || last_name.touched)
        }"
      />
      <div
        class="form-group__error"
        *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)"
      >
        <div *ngIf="last_name.errors?.required">Etternavn er nødvendig</div>
      </div>
    </div>

    <div class="form-group" *ngIf="type === formTypes.Register">
      <label for="mobilePhoneInput">Telefonnummer</label>
      <input
        type="text"
        class="form-input"
        id="mobilePhoneInput"
        name="mobile_phone"
        placeholder="Telefonnummer..."
        formControlName="mobile_phone"
        [ngClass]="{
          error:
            mobile_phone.invalid && (mobile_phone.dirty || mobile_phone.touched)
        }"
      />
      <div
        class="form-group__error"
        *ngIf="
          mobile_phone.invalid && (mobile_phone.dirty || mobile_phone.touched)
        "
      >
        <div *ngIf="mobile_phone.errors?.required">
          Telefonnummer er påkrevd
        </div>
        <div *ngIf="mobile_phone.errors?.pattern">
          Vennligst oppgi et gyldig telefonnummer
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="type === formTypes.Register">
      <label for="countryInput">Land</label>
      <ng-select
        [items]="countries"
        bindLabel="name"
        id="countryInput"
        bindValue="id"
        [multiple]="false"
        placeholder="Velg et land"
        formControlName="country_id"
        [readonly]="userCountry"
        [ngClass]="{
          error: country_id.invalid && (country_id.dirty || country_id.touched)
        }"
      >
      </ng-select>
      <div
        class="form-group__error"
        *ngIf="country_id.invalid && (country_id.dirty || country_id.touched)"
      >
        <div *ngIf="country_id.errors?.required">Landet er påkrevd</div>
      </div>
    </div>

    <div class="checkbox-form form-group" *ngIf="type === formTypes.Register">
      <input
        type="checkbox"
        class="form-input"
        id="newsletter_check"
        name="newsletter_check"
        formControlName="newsletter_check"
      />
      <label for="newsletter_check"
        >Vil du få tips og helseinformasjon fra Helse Privat?</label
      >
    </div>

    <div class="form-group" *ngIf="type === formTypes.Register">
      <div class="checkbox-wrapper checkbox-form">
        <input
          type="checkbox"
          class="form-input"
          id="accept_terms"
          name="accept_terms"
          formControlName="accept_terms"
        />
        <label for="accept_terms"
          >Ved å klikke på knappen godtar du &nbsp;
          <a [routerLink]="['/privacy']" target="_blank">vilkårene våre</a
          >.</label
        >
      </div>
      <div
        class="form-group__error terms-error"
        *ngIf="
          accept_terms.value === false &&
          (accept_terms.dirty || accept_terms.touched)
        "
      >
        <div *ngIf="accept_terms.value === false">
          Personvernerklæring er påkrevd
        </div>
      </div>
    </div>

    <div class="checkout-buttons-container">
      <div
        class="reset-pwd-link"
        (click)="resetPasswordEvent()"
        *ngIf="type === formTypes.Login"
      >
        Glemt passord?
      </div>
      <button class="form-btn main-button" type="submit">
        {{ btnLabelBasedOnType() }}
      </button>
    </div>
  </form>
</div>
