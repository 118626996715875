import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'my-select-timeslot-modal',
  templateUrl: './mobile-select-timeslot.component.html',
  styleUrls: ['./mobile-select-timeslot.component.scss']
})
export class MobileSelectTimeslotComponent implements OnInit, OnChanges {

  @Input() display: boolean;
  @Input() timeslots: any[];
  @Input() selectedTimeslot: number;
  @Input() displaySlot: (any) => string;

  @Output() applyChanges = new EventEmitter();

  constructor(
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.display === true) {
      this.renderer.addClass(document.body, 'modal-open');
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }

  closeModal() {
    this.applyChanges.emit(null);
  }

  selectTimeslot(index: number) {
    this.applyChanges.emit(index);
  }

}
