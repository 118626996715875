import {Component, Input, OnInit, Output, EventEmitter, SimpleChanges, Renderer2, OnChanges} from '@angular/core';

@Component({
  selector: 'my-select-doctor-modal',
  templateUrl: './mobile-select-doctor.component.html',
  styleUrls: ['./mobile-select-doctor.component.scss']
})
export class MobileSelectDoctorComponent implements OnInit, OnChanges {

  @Input() display: boolean;
  @Input() specialists: any[];
  @Input() timeslot: any;
  @Input() category: any;
  @Input() sortedTimeslots: boolean;
  @Input() showAll: boolean;

  @Output() applyChanges = new EventEmitter();
  @Output() applyChangesByCategory = new EventEmitter();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.display === true) {
      this.renderer.addClass(document.body, 'modal-open');
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }

  selectedSpecialist(event) {
    this.applyChanges.emit(event);
  }

  closeModal() {
    this.applyChanges.emit(null);
  }

  selectedSpecialistByCategory(event) {
    this.applyChangesByCategory.emit(event);
  }
}
