<div class="filter-section mb-4">
  <div class="row">
    <div class="col-md-5 col-12">
      <div
        class="filter-section__category py-2"
      >
        <select
          class="w-100 pl-3"
          [(ngModel)]="selectedCategory"
          (change)="filterByCategory()"
        >
          <option value="-1">Alle Kategorier</option>
          <option
            *ngFor="let cat of categories"
            [value]="cat.id"
            [disabled]="!cat.available"
          >
            {{ cat.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-7 col-12">
      <div class="filter-section__name py-2">
        <input
          type="text"
          placeholder="Søk etter lege"
          class="w-100 px-3"
          [(ngModel)]="searchedText"
          (keyup)="search()"
        />
      </div>
    </div>
  </div>
</div>
