<div class="py-5 my-5">
  <div
    class="w-50 mx-auto"
    *ngIf="!loadingAuth"
  >
    <h1 class="text-center">Registrer</h1>
    <my-auth-form
      [type]="currentFormType"
      [email]="email"
      [authFormGroup]="currentFormGroup"
      (formSubmitted)="formSubmitted($event)"
      (resetPassword)="resetPassword($event)"
    ></my-auth-form>
  </div>

  <div
    class="text-center"
    *ngIf="loadingAuth"
  >
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
