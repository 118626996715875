import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loadStripe, PaymentRequest, Stripe } from '@stripe/stripe-js';
import { defer, Observable } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from '../../../common/services/error-handling.service';
import { PaymentsService } from '../../../common/services/payments.service';
import {
  MobilePaymentTypes,
  PageQueryParams,
} from '../../common/models/payment.model';
import { initStripePaymentRequest } from '../../shared/utils/payments/init-stripe-payment-request';
import { onPaymentMethodHook } from '../../shared/utils/payments/on-payment-method-hook';

@Component({
  selector: 'app-payment-method-picker',
  templateUrl: './payment-method-picker.component.html',
  styleUrls: ['./payment-method-picker.component.scss'],
})
export class PaymentMethodPickerComponent implements OnInit {
  mountedButton: boolean = null;
  loadingNormalPayment: boolean = false;

  params$: Observable<PageQueryParams> = this.route.queryParams.pipe(
    map((params) => {
      localStorage.setItem('token', params.token);
      return {
        type: params['type'] as MobilePaymentTypes,
        amount: params['amount'] ? parseInt(params['amount'] as string) : 0,
        subId: params['subId'] ? parseInt(params['subId'] as string) : 0,
        date: params['date'] as string,
        title: params['title'] as string,
        payByCardLabel: params['payByCardLabel'] as string,
        token: params['token'] as string,
      };
    })
  );

  private stripe: Stripe;
  private paymentRequest: PaymentRequest;

  private paymentObj$ = this.params$.pipe(
    switchMap((params) =>
      params.type === MobilePaymentTypes.SUBSCRIPTION
        ? this.paymentsService.createSubApplePay(params.subId)
        : this.paymentsService.createSinglePaymentApplePay({
            amount: params.amount,
            date: params.date,
          })
    )
  );

  private initPaymentRequest$ = this.params$.pipe(
    switchMap((params) => initStripePaymentRequest(params, this.stripe))
  );

  constructor(
    private route: ActivatedRoute,
    private paymentsService: PaymentsService,
    private router: Router,
    private errorService: ErrorHandlingService
  ) {}

  ngOnInit() {
    defer(() => loadStripe(environment.stripe.publishableKey))
      .pipe(
        switchMap((stripe) => {
          this.stripe = stripe;
          return this.initPaymentRequest$;
        }),
        take(1)
      )
      .subscribe((result) => {
        const { paymentRequest } = result;
        this.paymentRequest = paymentRequest;

        defer(() => this.paymentRequest.canMakePayment())
          .pipe(take(1))
          .subscribe((data) => {
            console.log('APPLE PAY', data);
            if (data) {
              this.mountedButton = data.applePay;
            }
          });
      });
  }

  applePay() {
    this.paymentRequest.show();
    this.paymentRequest.on('paymentmethod', (event) => {
      this.mountedButton = false;
      this.paymentObj$.pipe(take(1)).subscribe((payment) => {
        window['webkit']?.messageHandlers?.cordova_iab.postMessage(
          JSON.stringify({ internalPaymentId: payment.internalPaymentId })
        );

        onPaymentMethodHook(event, this.stripe, payment.paymentId)
          .pipe(take(1))
          .subscribe((_) => {});
      });
    });
  }

  normalPayment() {
    this.loadingNormalPayment = true;
    this.params$
      .pipe(
        switchMap((params) =>
          (params.type === MobilePaymentTypes.SUBSCRIPTION
            ? this.paymentsService.createPaymentSub(params.subId)
            : this.paymentsService.createPayment({
                amount: params.amount,
                date: params.date,
              })
          ).pipe(map((data) => ({ ...data, amount: params.amount })))
        ),
        take(1),
        finalize(() => (this.loadingNormalPayment = false))
      )
      .subscribe(
        (data) => {
          window['webkit']?.messageHandlers?.cordova_iab.postMessage(
            JSON.stringify({ internalPaymentId: data.internalPaymentId })
          );

          this.router.navigateByUrl(
            `/payment?mobile=true&token=${localStorage.getItem(
              'token'
            )}&paymentId=${data.paymentId}&internalPaymentId=${
              data.internalPaymentId
            }&amount=${data.amount * 100}`
          );
        },
        (err) => {
          this.errorService.showError(err);
        }
      );
  }
}
