import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpHeaderResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { OptionsProvider as auth } from './options-provider';

export enum UserType {
  Doctor = 1,
  Patient = 2,
  Nurse = 3,
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = environment.base_url;
  private baseRoute = environment.base_route;

  customHeader: any;
  options: any;
  // loggedInUser;

  jwtHelper: JwtHelperService = new JwtHelperService();

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private loggedInUser = new Subject<any>();
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    // Create header for login request
    this.customHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.options = new HttpHeaderResponse({ headers: this.customHeader });

    // Set current user data
    // this.currentUserSubject = new BehaviorSubject<any>(
    //   // this.jwtHelper.decodeToken(localStorage['token'])
    // );
    // this.currentUser = this.currentUserSubject.asObservable();
    // this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('hjemmeliv-user')));
    // this.user = this.userSubject.asObservable();
  }

  // Get current user value
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(credentials) {
    return this.http
      .post(`${this.baseUrl}api/jwt/`, credentials, this.options)
      .pipe(
        map((response: any) => {
          // Set the token to the local storage
          localStorage.setItem(
            'token',
            response.access_token !== undefined ? response.access_token : ''
          );
          auth.changeOptions();

          return response;
        }, error => {
          return error.non_field_errors
        })
      );
  }

  register(data) {
    return this.http
      .post(
        `${this.baseUrl}${this.baseRoute}/register`,
        data,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  doesUserExist(email: string) {
    return this.http
      .get(
        `${this.baseUrl}${this.baseRoute}/user-exists?email=${encodeURIComponent(email.toLowerCase())}`,
        auth.guestOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public get userValue() {
    return this.userSubject.value;
  }

  getLoggedInUserData() {
    return this.http
      .get(`${this.baseUrl}rest/appointments/users/self/`, auth.options)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /* RESET PASSWORD */
  /**
   * @desc Get the token for the reset password flow
   * @param email
   */
  getEmailToken(email: string) {
    return this.http
      .post(
        `${this.baseUrl}api/reset_password/`,
        { email: email },
        { headers: { 'Content-Type': 'application/json'} }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * @desc Validate the token for the password reset flow
   * @param email
   * @param token
   */
  validateToken(email: string, token: string) {
    return this.http
      .post(
        `${this.baseUrl}api/reset_password/validate_token/`,
        { email: email, token: token },
        { headers: { 'Content-Type': 'application/json'} }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * @desc Resets the password for the user
   * @param email
   * @param token
   * @param password
   */
  resetPassword(email: string, token: string, password: string) {
    return this.http
      .post(
        `${this.baseUrl}api/reset_password/confirm/`,
        { email: email, token: token, password: password },
        { headers: { 'Content-Type': 'application/json'} }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
