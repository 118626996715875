<div class="calendar-container">
  <div class="btn-group d-flex my-4 px-3 justify-content-between align-items-center">
    <div class="fw-500 month-name" mwlCalendarToday [(viewDate)]="viewDate"
         (click)="getMonth(viewDate)">
      {{ currentMonth }} {{ currentYear }}
    </div>

    <div class="calendar-nav-btns">
      <button class="bg-transparent border-0 fw-500" mwlCalendarPreviousView
              [disabled]="isCurrentMonth()"
              (click)="getMonth(viewDate)" [view]="view" [(viewDate)]="viewDate">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-chevron-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
      </button>

      <button class="bg-transparent border-0 fw-500" (click)="getMonth(viewDate)"
              mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
    </div>
  </div>

  <mwl-calendar-month-view
    locale="nb-NO"
    [viewDate]="viewDate"
    [events]="events"
    (beforeViewRender)="beforeMonthViewRender($event)"
    (dayClicked)="dayClicked($event.day)">
  </mwl-calendar-month-view>

  <div
    #tooltipContainer
    class="d-none tooltip-container"
  >
    2 doctors
  </div>
</div>
