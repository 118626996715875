import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {HelsePrivatModule} from "./modules/helse-privat/helse-privat.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from "@angular/router";
import {CalendarDateFormatter, CalendarModule, DateAdapter} from "angular-calendar";

import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import {DatePipe} from "@angular/common";
import {JwtInterceptor} from "./modules/helse-privat/common/guards/jwt.interceptor";
import {ErrorInterceptor} from "./modules/helse-privat/common/guards/error.interceptor";
import {CustomDateFormatter} from "./modules/helse-privat/common/services/custom-date-formatter";
import {HeaderComponent} from "./modules/helse-privat/components/common/header/header.component";
import {FooterComponent} from "./modules/helse-privat/components/common/footer/footer.component";
import { NgSelectModule } from '@ng-select/ng-select';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HelsePrivatModule,
    RouterModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    }),
    NgSelectModule
  ],
  providers: [
    DatePipe,
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter }
  ],
  bootstrap: [AppComponent, HeaderComponent, FooterComponent]
})
export class AppModule { }
