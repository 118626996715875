import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'my-filter-section',
  templateUrl: './filter-section.component.html',
  styleUrls: ['./filter-section.component.scss']
})
export class FilterSectionComponent implements OnInit, OnChanges {

  @Input() categories: any[];
  @Input() categoryIndex: number;
  @Input() specialists: any[];
  @Input() currentSearchTerm?: string = '';

  @Output() startedSearch = new EventEmitter();
  @Output() filteredCategory = new EventEmitter();

  searchedText: string;
  inputDebounce: any;
  selectedCategory: number;

  constructor() { }

  ngOnInit(): void {
    this.searchedText = this.currentSearchTerm;
    if (this.categoryIndex === -1) {
      this.selectedCategory = -1;
    } else {
      this.selectedCategory = this.categories[this.categoryIndex].id;
    }

    this.categories = this.categories.map(cat => {
      return {
        ...cat,
        available: this.isCategoryAvailable(cat)
      };
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.categoryIndex) {
      if (this.categoryIndex === -1) {
        this.selectedCategory = -1;
      } else {
        this.selectedCategory = this.categories[this.categoryIndex].id;
      }
    }
  }

  search() {
    if (this.inputDebounce !== undefined) {
      clearTimeout(this.inputDebounce);
    }

    this.inputDebounce = setTimeout(() => {
      this.startedSearch.emit(this.searchedText);
    }, 700);
  }

  filterByCategory() {
    this.filteredCategory.emit({
      id: this.selectedCategory
    });
  }

  isCategoryAvailable(category) {
    return this.specialists.find(
      specialist => specialist.categories.find(cat => cat.category.id === category.id)
    ) !== undefined;
  }

}
