import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UIHelper} from "../../common/helper/ui-helper";
import {CalendarEvent, CalendarMonthViewDay, CalendarView} from "angular-calendar";
import * as moment from 'moment';
import {SpecialistsService} from "../../../common/services/specialists.service";
import {catchError, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AppointmentsService} from "../../../common/services/appointments.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorHandlingService} from "../../../common/services/error-handling.service";
import {IntrojsService} from "../../../common/services/introjs.service";
import swal from 'sweetalert2';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingComponent implements OnInit, AfterViewInit {

  timeslots = []; // only the timeslots displayed to the user
  allTimeslots = []; // all timeslots returned by the api
  allTimeslotsCurrentDay = []; // all the timeslots for the current day
  categories = [];
  doctors = [];
  calendarEvents: CalendarEvent[] = [];

  selectedTimeslot: any;

  /**
   * @desc Selected category index from filter
   */
  selectedCategory: number;

  /**
   * @desc Selected category from the selected specialist
   */
  selectedUserCategory: any;

  loadingData = false;
  displayCards = true;
  searchedByName = false;

  /**
   * Used for the specialist card to detect changes to a specialist's timeslots
   */
  sortedTimeslots = false;

  currentSearchTerm = '';

  allSpecialists = [];
  specialists = [];

  apiObj = {
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
    duration: 30,
    users: []
  };

  today = moment(new Date()).format('YYYY-MM-DD');

  selectDoctorModal = false;
  selectTimeslotModal = false;

  updatingCalendar = false;
  countries = [];

  /**
   * @desc Undefined if not doctor manually selected by user
   */
  currentlySelectedSpecialist: any;

  constructor(
    private specialistsService: SpecialistsService,
    private appointmentsService: AppointmentsService,
    private errorHandlingService: ErrorHandlingService,
    private router: Router,
    private route: ActivatedRoute,
    public introService: IntrojsService,
  ) {

  }

  ngOnInit(): void {
    this.appointmentsService.generateUuid();
    this.initData();
    this.getCountries();
    // this.filterByCategory(-1);
  }

  ngAfterViewInit(): void {
    // this.introService.startTutorial();
  }

  getCountries() {
    this.appointmentsService.getCountries().subscribe((response) => {
      this.countries = response;

      const inputOptions = this.countries?.reduce((currentRes, country) => {
        const obj1 =  { ...currentRes };
        obj1[country.id] = country.name;
        return obj1;
      }, {});

      const userCountry = localStorage.getItem('country');

      if (!userCountry) {
        swal.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: false,
          title: 'Please select your country',
          input: 'select',
          inputOptions,
          inputPlaceholder: 'Select a country',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.length) {
                resolve('');
              } else {
                resolve('You need to select a country!');
              }
            });
          }
        }).then(result => {
          localStorage.setItem('country', result.value);
          this.initData();
        });
      }
    });
  }

  /**
   * @desc Send requests to get the data necessary (specialists, categories, timeslots)
   */
  initData() {
    this.loadingData = true;
    this.specialistsService
      .getSpecialistsAPI()
      .subscribe(specialists => {
        this.specialists = specialists;
        this.allSpecialists = specialists;
        this.updateApiObjUsers();

        this.specialistsService
          .getDoctorsCategories()
          .subscribe(categories => {
            categories.sort((cat1, cat2) => cat1.name.localeCompare(cat2.name));
            this.categories = categories;
            this.categories = this.categories.map(cat => {
              return {
                ...cat,
                available: this.isCategoryAvailable(cat)
              };
            });
            // this.selectedCategory = -1;
            this.selectedCategory = this.categories.indexOf(
              this.categories.find(cat => cat.is_default === true)
            );
            if (this.selectedCategory === -1) {
              this.selectedCategory = -1;
            }

            this.apiObj.duration = this.getDurationByCategoryIndex(this.selectedCategory);
            const filteredSpecialistsByCat = this.specialists.filter(spec =>
              spec.categories.find(cat =>
                cat.category.id === this.categories[this.selectedCategory].id) !== undefined);
            const usersIdsString = filteredSpecialistsByCat.reduce((acc, specialist, index) => {
              if (index < filteredSpecialistsByCat.length - 1) {
                return specialist.user.id + '|' + acc;
              }
              return acc + specialist.user.id;
            }, '');
            const timeslotsApiObj = {
              ...this.apiObj,
              users: usersIdsString,
              end_date: moment(this.apiObj.start_date, 'YYYY-MM-DD').add(3, 'months').format('YYYY-MM-DD')
            };

            this.specialistsService
              .countTimeslots(timeslotsApiObj)
              .subscribe(timeslotsCount => {
                this.updateBadgeNumbers(timeslotsCount);

                // check if there is a 'doctorId' query param to automatically select that doctor
                this.route.queryParams
                  .subscribe(params => {
                    this.selectedUserCategory = this.categories.find(cat => cat.is_default);
                    this.selectedCategory = this.categories.indexOf(
                      this.categories.find(cat => cat.id === this.selectedUserCategory.id)
                    );

                    if (params.doctorId) {
                      let selectedDoctor = this.allSpecialists.find(item => item.user.id === parseInt(params.doctorId));

                      if (selectedDoctor) {
                        // If changed doctor, update selected category
                        this.selectedUserCategory = selectedDoctor.categories[0].category;
                        this.selectedCategory = this.categories.indexOf(
                          this.categories.find(cat => cat.id === this.selectedUserCategory.id)
                        );

                        // this.selectedCategory = -1;
                        this.getTimeslots(false, false).subscribe(_ => {
                          selectedDoctor = this.specialists.find(item => item.user.id === parseInt(params.doctorId));
                          this.selectSpecialist(selectedDoctor).subscribe(_ => {
                          });
                        });
                      } else {
                        // this.selectedCategory = -1;
                        this.getTimeslots(false, false).subscribe(_ => {
                        });
                      }
                    } else if (params.categoryId) {
                      const category = this.categories.find(x => x.id.toString() === params.categoryId);
                      if (category !== undefined && category.available)  {
                        this.getTimeslots(false, false).subscribe(_ => {
                          this.filterByCategory(params.categoryId);
                        });
                      } else {
                        this.getTimeslots(false, false).subscribe(_ => {});
                      }
                    }  else {
                      // this.selectedCategory = -1;
                      this.getTimeslots(false, false).subscribe(_ => {
                        // this.introService.startTutorial();
                      });
                    }
                  });
              }, err => {
                this.errorHandlingService.showError(err);
                this.loadingData = false;
              });
          }, err => {
            this.errorHandlingService.showError(err);
            this.loadingData = false;
          });
      }, err => {
        this.errorHandlingService.showError(err);
        this.loadingData = false;
      });
  }

  isCategoryAvailable(category) {
    return this.specialists.find(
      specialist => specialist.categories.find(cat => cat.category.id === category.id)
    ) !== undefined;
  }

  hasAtLeastOneTimeslot(resultItem) {
    return !Object.keys(resultItem).every(catKey => {
      return resultItem[catKey] === null
        || Object.keys(resultItem[catKey]).every(dateKey => {
          return !resultItem[catKey][dateKey]
            || resultItem[catKey][dateKey].length === 0;
        })
    });
  }

  /**
   * @desc Get timeslots according to the apiObj and map them correctly to the timeslots array. Also, filter the specialists array accordingly
   * @param cardsLoading
   * @param changedDate
   */
  getTimeslots(cardsLoading = false, changedDate = true) {
    if (this.apiObj.users.length === 0) {
      return new Observable<void>();
    }

    if (cardsLoading === false) {
      this.loadingData = true;
    } else {
      this.displayCards = false;
    }

    this.timeslots = [];
    this.allTimeslotsCurrentDay = [];
    this.allTimeslots = [];
    return this.specialistsService
      .getTimeslotsByCategory(this.apiObj)
      .pipe(
        map(res => {
          // only keep the specialists which have available timeslots
          // add the timeslots key to each specialist and save all the timeslots in an array
          const remainingUserIds = Object.keys(res.results);
          let allTimeslots = [];

          this.allSpecialists = this.allSpecialists
            .filter(item => {
              return remainingUserIds.includes(item.user.id.toString())
                && this.hasAtLeastOneTimeslot(res.results[item.user.id.toString()]);
            })
            .map(specialist => {
              const userId = specialist.user.id.toString();
              let mergedSpecialistTimeslots = [];
              for (let i = 0; i < specialist.categories.length; ++i) {
                const categoryId = specialist.categories[i].category.id;
                const timeslotsObj = res.results[userId][categoryId];
                specialist.categories[i] = {
                  ...specialist.categories[i],
                  timeslots: timeslotsObj[Object.keys(timeslotsObj)[0]]
                };

                if (!this.searchedByName) {
                  if (specialist.categories[i].category.id === this.categories[this.selectedCategory].id) {
                    allTimeslots.push(...specialist.categories[i].timeslots);
                  }
                }

                // const resultedSpecialist = this.specialists.find(item => item.user.id === specialist.user.id);
                // if (!this.searchedByName || (this.searchedByName && resultedSpecialist !== undefined)) {
                  specialist.categories[i].timeslots.forEach(slot => {
                    if (mergedSpecialistTimeslots.find(elem => elem[0].start === slot[0].start) === undefined) {
                      mergedSpecialistTimeslots.push(slot);
                    }
                  });
                // }
              }

              if (this.searchedByName) {
                allTimeslots.push(...mergedSpecialistTimeslots);
              }
              return {
                ...specialist,
                timeslots: mergedSpecialistTimeslots
              };
            });

          const tmpSpecialists = this.specialists;
          this.specialists = this.allSpecialists
            .filter(specialist => {
              let hasCategory = (
                (
                  this.selectedCategory !== -1
                  && specialist.categories.find(cat => cat.category.id === this.categories[this.selectedCategory].id) !== undefined
                )
                || this.selectedCategory === -1
              );

              if (this.searchedByName) {
                hasCategory = hasCategory && tmpSpecialists.find(item => item.user.id === specialist.user.id) !== undefined;
              }

              return hasCategory;
            });

          this.filterAndSortSpecialistsAndTimeslots(allTimeslots);

          if (this.timeslots.length > 0) {
            if (cardsLoading === false) {
              this.loadingData = false;
            } else {
              this.displayCards = true;
            }
          } else {
            // if (!changedDate) {
            this.jumpToFirstDate();
            // } else {
              if (cardsLoading === false) {
                this.loadingData = false;
              } else {
                this.displayCards = true;
              }
            // }
          }
        }, err => {
          if (cardsLoading === false) {
            this.loadingData = false;
          } else {
            this.displayCards = true;
          }
        })
      );
  }

  filterAndSortSpecialistsAndTimeslots(allTimeslots) {
    this.sortedTimeslots = !this.sortedTimeslots;
    console.log('All Timeslots', allTimeslots);
    // only save the timeslots with an unique start date
    allTimeslots = allTimeslots.map(slot => slot[0]);
    for (let i = 0; i < allTimeslots.length; i++) {
      if (this.allTimeslotsCurrentDay.find(elem => elem.start === allTimeslots[i].start) === undefined) {
        this.allTimeslots.push(allTimeslots[i]);
        if (moment(allTimeslots[i].start).format('YYYY-MM-DD') === moment(this.apiObj.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD')) {
          this.allTimeslotsCurrentDay.push(allTimeslots[i]);
        }
      }
    }

    // sort the timeslots increasingly based on the start date
    this.allTimeslotsCurrentDay.sort((slot1, slot2) => {
      return moment(slot1.start).unix() > moment(slot2.start).unix() ? 1 : -1;
    });

    // sort timeslots for all specialists
    // if (this.apiObj.start_date === moment(new Date()).format('YYYY-MM-DD')) {
    this.specialists = this.specialists.map(specialist => {
      specialist.timeslots.sort(this.sortTimeslotsArrayAsc);
      for (let i = 0; i < specialist.categories.length; ++i) {
        specialist.categories[i].timeslots.sort(this.sortTimeslotsArrayAsc);
      }

      return specialist;
    });
    // }

    // sort the specialists increasingly based on their first available timeslot
    this.specialists.sort((item1, item2) => {
      let firstTimeslot1 = moment(item1.timeslots[0][0].start).unix();
      let firstTimeslot2 = moment(item2.timeslots[0][0].start).unix();

      if (!this.searchedByName) {
        // if we are displaying only a certain category's specialists
        const selectedCategoryIndex1 = this.getCategoryIndexFromId(item1.categories, this.categories[this.selectedCategory].id);
        const selectedCategoryIndex2 = this.getCategoryIndexFromId(item2.categories, this.categories[this.selectedCategory].id);

        if (selectedCategoryIndex1 !== -1) {
          firstTimeslot1 = moment(item1.categories[selectedCategoryIndex1].timeslots[0][0].start).unix();
        }
        if (selectedCategoryIndex2 !== -1) {
          firstTimeslot2 = moment(item2.categories[selectedCategoryIndex2].timeslots[0][0].start).unix();
        }
      }

      return firstTimeslot1 > firstTimeslot2 ? 1 : -1;
    });

    const selectedCategoryIndex = this.specialists[0].categories.indexOf(
      this.specialists[0].categories.find(cat => cat.category.id === this.categories[this.selectedCategory]?.id)
    );
    this.selectedTimeslot = this.allTimeslotsCurrentDay.indexOf(
      this.allTimeslotsCurrentDay.find(slot => {
          if (selectedCategoryIndex !== -1) {
            return this.specialists[0].categories[selectedCategoryIndex].timeslots.find(
              slots => slots[0].start === slot.start
            ) !== undefined;
          }

          return  slot.start === this.specialists[0].timeslots[0][0].start;
        }
      )
    );

    console.log('SELECTED TIMESLOT', this.selectedTimeslot, this.allTimeslotsCurrentDay, this.specialists);

    if (this.specialists && this.specialists.length > 0) {
      this.timeslots = JSON.parse(JSON.stringify(this.allTimeslotsCurrentDay));
      console.log('TIMESLOTS BEFORE FILTER', this.timeslots);

      if (this.timeslots.length === 0) {
        this.jumpToFirstDate();
      } else {
        if (!this.searchedByName) {
          this.updateSelectedUserCatBasedOnSlot();
          if (this.selectedCategory !== -1) {
            this.selectedUserCategory = this.specialists[0].categories.find(
              cat => cat.category.id === this.categories[this.selectedCategory].id
            );
          }
        } else {
          this.timeslots = this.timeslots.filter(slot => {
            return this.specialists.find(specialist => {
              return specialist.timeslots.find(specSlots => specSlots[0].start === slot.start) !== undefined;
            }) !== undefined;
          });

          console.log('TIMESLOTS AFTER FILTER', this.timeslots, this.specialists);

          this.selectedTimeslot = this.timeslots.indexOf(
            this.timeslots.find(slot => slot.start === this.specialists[0].timeslots[0][0].start)
          );
          // this.selectedUserCategory = this.specialists[0].categories[0];

          this.updateSelectedUserCatBasedOnSlot();
        }
      }
    }
  }

  /**
   * @desc Get index of category object from user object only knowing the id
   * @param categories
   * @param catId
   */
  getCategoryIndexFromId(categories, catId: number) {
    return categories.indexOf(
      categories.find(cat => cat.category.id === catId)
    );
  }

  /**
   * @desc Sort function for a timeslots array corresponding to a specialist
   * @param item1
   * @param item2
   */
  sortTimeslotsArrayAsc(item1, item2) {
    return moment(item1[0].start) > moment(item2[0].start) ? 1 : -1;
  }

  /**
   * @desc Sort function for a timeslots array after a new timeslot is selected
   * @param slot1
   * @param slot2
   */
  sortTimeslotsBasedOnSelection(slot1, slot2) {
    const slot1Diff = moment(slot1[0]?.start).unix() - moment(this.timeslots[this.selectedTimeslot]?.start).unix();
    const slot2Diff = moment(slot2[0]?.start).unix() - moment(this.timeslots[this.selectedTimeslot]?.start).unix();

    if (slot1Diff < 0 && slot2Diff >= 0) {
      return 1;
    }
    if (slot1Diff >= 0 && slot2Diff < 0) {
      return -1;
    }
    if (slot1Diff < 0) {
      return slot1Diff > slot2Diff ? -1 : 1;
    }

    return slot1Diff < slot2Diff ? -1 : 1;
  }

  /**
   * @desc Correctly format the start date of a time slot. Used in the html
   * @param slot
   * @param format
   */
  displaySlot(slot, format = 'HH:mm') {
    if (slot === undefined) {
      return moment(new Date()).format(format);
    }

    return moment(slot.start).format(format);
  }

  /**
   * @desc When a new timeslot is selected, rearrange the specialists list by sorting it based on the available timeslots
   * @param index
   */
  selectTimeslot(index: number) {
    const timeslot = this.timeslots[index];
    this.updateListsAfterTimeslotSelected(index);

    // change boolean value to trigger change in specialist-card component
    this.sortedTimeslots = !this.sortedTimeslots;
  }

  /**
   * @desc Updates the specialists list after a new timeslot was selected
   * @param index
   */
  updateListsAfterTimeslotSelected(index: number) {
    this.selectedTimeslot = index;

    // map the specialists array to contain the specialists with the available selected timeslot
    this.specialists = this.specialists.map(specialist => {
      if (this.searchedByName) {
        specialist.timeslots.sort(this.sortTimeslotsBasedOnSelection.bind(this));
      } else {
        const catIndex = this.getCategoryIndexFromId(specialist.categories, this.categories[this.selectedCategory].id);
        if (catIndex !== -1) {
          specialist.categories[catIndex].timeslots.sort(this.sortTimeslotsBasedOnSelection.bind(this));
        }
      }
      return specialist;
    });

    // sort the specialists list to put the ones with the correct availability first
    this.specialists.sort((specialist1, specialist2) => {
      // make sure to fetch the timeslot correctly based on the 'all' flag
      let start1 = specialist1.timeslots[0][0]?.start;
      let start2 = specialist2.timeslots[0][0]?.start;
      if (!this.searchedByName) {
        const catIndex1 = this.getCategoryIndexFromId(specialist1.categories, this.categories[this.selectedCategory].id);
        const catIndex2 = this.getCategoryIndexFromId(specialist2.categories, this.categories[this.selectedCategory].id);
        if (catIndex1 !== -1) {
          start1 = specialist1.categories[catIndex1].timeslots[0][0]?.start;
        }
        if (catIndex2 !== -1) {
          start2 = specialist2.categories[catIndex2].timeslots[0][0]?.start;
        }
      }

      if (start1 === this.timeslots[this.selectedTimeslot]?.start) {
        // if the first specialist has slot at the selected date, put him first
        if (start2 !== this.timeslots[this.selectedTimeslot]?.start) {
          return -1;
        }

        // if both have slot at the selected date, it doesn't matter
        return 1;
      }

      // if neither specialists have slot at the selected date, sort them decreasingly by start date
      if (start2 !== this.timeslots[this.selectedTimeslot]?.start) {
        return moment(start1).unix() > moment(start2).unix() ? 1 : -1;
      }

      // if the second specialist has slot at the selected date, put him first
      return 1;
    });

    this.updateSelectedUserCatBasedOnSlot();
  }

  /**
   * @desc Event triggered when a new specialist is selected
   * @param event
   */
  selectedSpecialist(event) {
    this.selectSpecialist(event, false).subscribe(_ => {
      if (this.specialists[0].categories.find(cat => cat.category.id === this.selectedUserCategory.category.id) === undefined) {
        // this.selectedUserCategory = this.specialists[0].categories[0];
        this.updateSelectedUserCatBasedOnSlot();
      }
    });

    if (this.specialists[0].categories.find(cat => cat.category.id === this.selectedUserCategory.category.id) === undefined) {
      // this.selectedUserCategory = this.specialists[0].categories[0];
      this.updateSelectedUserCatBasedOnSlot();
    }
  }

  selectSpecialist(obj, getDurationFromCat = true): Observable<void> {
    // if the specialist's availability is not for today, then update the calendar and timeslots
    if (this.searchedByName) {
      // if we are disregarding the category
      if (moment(obj.timeslots[0][0].start).format('YYYY-MM-DD') === this.apiObj.start_date) {
        // if the specialist has a slot today, just change the slot
        const selectedSlot = this.timeslots.indexOf(
          this.timeslots.find(slot => slot.start === obj.timeslots[0][0].start)
        );
        this.selectSpecialistSameDay(obj, selectedSlot);
      } else {
        // if it's a different day, get new timeslots and select the specialist
        return this.selectSpecialistDifferentDay(obj, obj.timeslots[0][0].start);
      }
    } else {
      // if we are taking category into account
      const catIndex = this.getCategoryIndexFromId(obj.categories, this.categories[this.selectedCategory].id);
      if (catIndex !== -1) {
        if (moment(obj.categories[catIndex].timeslots[0][0].start).format('YYYY-MM-DD') === this.apiObj.start_date) {
          // if the specialist has a slot today, just change the slot
          const selectedSlot = this.timeslots.indexOf(
            this.timeslots.find(slot => slot.start === obj.timeslots[0][0].start)
          );
          this.selectSpecialistSameDay(obj, selectedSlot);
        } else {
          // if it's a different day, get new timeslots and select the specialist
          return this.selectSpecialistDifferentDay(obj, obj.categories[catIndex].timeslots[0][0].start);
        }
      }
    }
    return new Observable<void>();
  }

  /**
   * @desc Function called when a specialist is selected that has an available timeslot on the same day
   * @param obj
   * @param selectedSlot
   */
  selectSpecialistSameDay(obj, selectedSlot) {
    // select the new timeslot
    this.selectTimeslot(selectedSlot);

    // move selected specialist to first position
    const specialistIndex = this.specialists.indexOf(
      this.specialists.find(specialist => specialist.user.id === obj.user.id)
    );
    [this.specialists[0], this.specialists[specialistIndex]] = [this.specialists[specialistIndex], this.specialists[0]];
  }

  /**
   * @desc Function called when a specialist is selected that has an available timeslot on a different day
   * @param obj
   * @param date
   */
  selectSpecialistDifferentDay(obj, date) {
    this.updateApiObjDates(moment(date).toDate());
    return this.getTimeslots()
      .pipe(
        map(_ => {
          const slotIndex = this.timeslots.indexOf(
            this.timeslots.find(slot => slot.start === date)
          );
          this.selectSpecialistSameDay(obj, slotIndex);
        })
      );
  }

  /**
   * @desc Event triggered when a new date is selected on the calendar
   * @param event
   */
  changedDate(event) {
    this.updateApiObjDates(event.date);
    this.getTimeslots(true).subscribe(_ => {});
  }

  /**
   * @desc Updates the apiObj by setting the correct date format
   * @param date
   */
  updateApiObjDates(date: Date) {
    this.apiObj.start_date = moment(date).format('YYYY-MM-DD');
    this.apiObj.end_date = moment(date).add(1, 'days').format('YYYY-MM-DD');
  }

  /**
   * @desc Convert date from string to Date with the proper format
   * @param dateStr
   * @param format
   */
  getDateFromString(dateStr: string, format = 'YYYY-MM-DD'): Date {
    return moment(dateStr, format).toDate();
  }

  /**
   * @desc Update the users key from the apiObj by mapping the specialist ids from the specialists array
   */
  updateApiObjUsers() {
    this.apiObj.users = this.specialists.reduce((acc, specialist, index) => {
      if (index < this.specialists.length - 1) {
        return specialist.user.id + '|' + acc;
      }
      return acc + specialist.user.id;
    }, '');
  }

  /**
   * @desc Check if the specialists array is empty or not. Used in the html
   */
  specialistsFound() {
    return this.specialists && this.specialists.length > 0;
  }

  /**
   * @desc Event triggered on click of the next step button. Forms the newAppointment object and navigates to the checkout page
   */
  nextStep() {
    this.loadingData = true;
    const userCat = this.specialists[0].categories
      .find(cat => cat.category.id === this.selectedUserCategory.category.id);
    const selectedUserTimeslots = userCat.timeslots.find(
      slots => slots[0].start === this.timeslots[this.selectedTimeslot].start
    );

    this.selectedUserCategory = userCat;
    this.appointmentsService.newAppointment = {
      ...this.appointmentsService.newAppointment,
      category_setting_id: this.selectedUserCategory.setting_id,
      timeslot_ids: selectedUserTimeslots.map(slot => slot.id)
    };

    const appointmentInfo = {
      specialist: this.specialists[0],
      category: this.selectedUserCategory,
      timeslot: this.timeslots[this.selectedTimeslot],
      price: this.selectedUserCategory.price
    };

    localStorage.setItem('appt_info', JSON.stringify(appointmentInfo));
    localStorage.setItem('new_appt', JSON.stringify(this.appointmentsService.newAppointment));

    this.appointmentsService
      .lockTimeslot(this.appointmentsService.newAppointment.timeslot_ids)
      .subscribe(res => {
        this.loadingData = false;
        this.router.navigateByUrl('/checkout');
      }, err => {
        this.errorHandlingService.showError(err);
        this.loadingData = false;
      });
  }

  /**
   * @desc Event triggered when the user searches a specialist by their name
   * @param event
   */
  searchByName(event) {
    if (event) {
      if (event !== '') {
        this.searchedByName = true;
        this.currentSearchTerm = event;
        this.filterByCategory('-1');
        return;
      }
    }

    this.searchedByName = false;
    this.currentSearchTerm = event;
    if (this.selectedCategory === -1) {
      this.filterByCategory('-1');
    } else {
      this.filterByCategory(this.categories[this.selectedCategory].id.toString());
    }
  }

  /**
   * @desc Event triggered when the user filters the specialists by their category
   * @param event
   */
  filterByCategory(event) {
    if (event) {
      let catId = -1;
      if (event.hasOwnProperty('id')) {
        // the function has been triggered by the dropdown element in the ui
        catId = parseInt(event.id);
        this.currentSearchTerm = '';
        this.searchedByName = false;
      } else {
        // the function has been triggered by another action on the site
        catId = parseInt(event);
      }
      const category = this.categories.find(cat => cat.id === catId);

      this.searchedByName = catId === -1;


      // filter the specialists and only keep those with the correct category
      this.selectedCategory = this.categories.indexOf(category);
      this.specialists = this.allSpecialists
        .filter(specialist => {
          if (this.currentSearchTerm && this.currentSearchTerm !== '') {
            return (
              specialist.user.first_name.toLowerCase().includes(this.currentSearchTerm.toLowerCase())
              || specialist.user.last_name.toLowerCase().includes(this.currentSearchTerm.toLowerCase())
            );
          }

          return catId === -1 || specialist.categories.find(cat => cat.category.id === catId) !== undefined;
        });

      let allTimeslots = [];
      this.timeslots = [];
      this.allTimeslotsCurrentDay = [];
      this.allTimeslots = [];
      this.specialists.forEach(specialist => {
        if (this.selectedCategory === -1) {
          allTimeslots.push(...specialist.timeslots);
        } else {
          const catIndex = this.getCategoryIndexFromId(specialist.categories, this.categories[this.selectedCategory].id);
          if (catIndex !== -1) {
            allTimeslots.push(...specialist.categories[catIndex].timeslots);
          }
        }
      });


      this.updatingCalendar = true;
      let timeslotsApiObj: any = {
        ...this.apiObj,
        end_date: moment(this.apiObj.start_date, 'YYYY-MM-DD').add(3, 'months').format('YYYY-MM-DD'),
      };
      timeslotsApiObj.start_date = moment(new Date()).format('YYYY-MM-DD');

      if (this.selectedCategory !== -1 || this.searchedByName) {
        timeslotsApiObj = {
          ...timeslotsApiObj,
          users: this.specialists.reduce((acc, specialist, index) => {
            if (index < this.specialists.length - 1) {
              return specialist.user.id + '|' + acc;
            }
            return acc + specialist.user.id;
          }, '')
        };
      }

      if (timeslotsApiObj.users && timeslotsApiObj.users.length > 0) {
        this.specialistsService
          .countTimeslots(timeslotsApiObj)
          .subscribe(timeslotsCount => {
            this.updateBadgeNumbers(timeslotsCount);
            this.filterAndSortSpecialistsAndTimeslots(allTimeslots);
          });
      } else {
        this.filterAndSortSpecialistsAndTimeslots(allTimeslots);
      }
    }
  }

  /**
   * @desc Event triggered when, on the mobile version, a new doctor is selected in the modal
   * @param event
   */
  mobileDoctorSelected(event) {
    this.selectDoctorModal = false;
    if (event) {
      this.selectSpecialist(event, false).subscribe(_ => {
      });
    }
  }

  /**
   * @desc Event triggered when, on the mobile version, a new timeslot is selected in the modal
   * @param event
   */
  mobileTimeslotSelected(event) {
    this.selectTimeslotModal = false;
    if (event) {
      this.selectTimeslot(event);
    }
  }

  /**
   * @desc Event triggered when the user changes the selected category for the selected specialist
   * @param event
   */
  changedSelectedCategory(event) {
    if (typeof event === 'string') {
      this.selectedUserCategory = this.specialists[0].categories.find(cat => cat.category.id === parseInt(event));
    } else {
      this.selectedUserCategory = this.specialists[0].categories.find(cat => cat.category.id === event);
    }
  }

  // when user clicks the category from card, the doctors are filtered by that category and the doctor from that card is selected
  selectedSpecialistByCategory(event) {
    this.selectedUserCategory = event.category;
    this.selectedCategory = this.categories.indexOf(
      this.categories.find(cat => cat.id === event.category.category.id)
    );

    this.filterByCategory(this.selectedUserCategory.category.id);
    this.selectSpecialist(event.doctor)
      .subscribe(_ => {
        this.selectedUserCategory = event.category;
      });
    this.selectDoctorModal = false;
  }

  getDurationByCategoryIndex(index: number): number {
    if (index === -1) {
      return 5;
    }

    return this.categories[index].appointment_duration;
  }

  updateBadgeNumbers(timeslotsCount) {
    this.calendarEvents = [];
    const results = timeslotsCount.results;
    Object.keys(results).forEach(date => {
      const newEvent = {
        start: moment(date, 'YYYY-MM-DD').toDate(),
        title: 'Slot Name',
        allDay: true
      };
      for (let i = 0; i < results[date]; ++i) {
        this.calendarEvents.push(newEvent);
      }
    });

    this.updatingCalendar = false;
  }

  jumpToFirstDate() {
    const startEvent = this.calendarEvents.find(event => {
      const eventDate = moment(event.start);
      const selectedDate = moment(this.apiObj.start_date, 'YYYY-MM-DD');
      return eventDate.format('Y-M-D') === selectedDate.format('Y-M-D')
        || eventDate.isAfter(selectedDate);
    });

    const startDate = moment(startEvent.start);
    this.apiObj.start_date = startDate.format('YYYY-MM-DD');
    this.apiObj.end_date = startDate.add(1, 'days').format('YYYY-MM-DD');
    this.getTimeslots().subscribe(_ => {});
  }

  /**
   * @desc Gets the available categories for the currently selected timeslot to filter out the categories for which that timeslot is not available
   */
  updateSelectedUserCatBasedOnSlot() {
    // get the user categories for which the selected timeslot is available
    const userCategories = this.specialists[0].categories.filter(cat => {
      return cat.timeslots.find(slots => slots[0]?.start === this.timeslots[this.selectedTimeslot]?.start) !== undefined;
    });

    // update selectedUserCategory with the category for which the timeslot is actually available
    if (this.selectedCategory === -1) {
      this.selectedUserCategory = userCategories[0];
    }
  }
}
