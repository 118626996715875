import { Injectable } from '@angular/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }

  async success(message: string) {
    return await Swal.fire({
      title: 'Suksess',
      text: message,
      icon: 'success',
      confirmButtonText: 'OK'
    });
  }

  async areYouSurePrompt(message: string) {
    return await Swal.fire({
      title: 'Er du sikker',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Ja',
      showCancelButton: true,
      cancelButtonText: 'Nei'
    });
  }
}
