export interface PaymentLongpollingResponse {
  external_payment_id: string;
  status: PaymentStatus;
  payment_response?: any;
}

export interface Payment {
  internalPaymentId: number;
  paymentId: string;
}

export enum PaymentStatus {
  INITIATED = 'initiated',
  CHECKOUT_COMPLETED = 'checkout-completed',
  SUCCESS = 'charge-success',
  FAIL = 'charge-failed',
}
