<div class="py-5 my-5">
  <div
    class="w-100 mx-auto"
  >
    <h1 class="text-center">Personvernerklæring</h1>
    <div class="privacy-wrapper my-5">
      <p>
        Vi lagrer følgende personopplysninger om våre kunder: navn, adresse,
        telefonnummer, e-postadresse, kjønn, fødselsdato, passord for min
        side. Vi lagrer i tillegg kjøpshistorikk og adferdsmønster på vår
        nettside, dvs. opplysninger om hvordan den enkelte kunde navigerer på
        siden. Vi behandler opplysningene for å kunne gjennomføre våre
        forpliktelser etter avtale med deg.

        Informasjon om navn, adresse, telefon, e-postadresse benyttes for å
        oppfylle kjøpsavtalen. Grunnlaget for denne behandlingen er
        personvernforordningens artikkel Art 6 (b). Der du har samtykket vil
        det benyttes også opplysningene til å gi deg informasjon, tilbud og
        service i forbindelse med ditt kjøp via e-post, telefon og SMS.
        Grunnlaget for denne behandlingen er personvernforordningen Art 6 (a).
        Du kan når som helst takke nei til å motta slik informasjon fra oss.
        Der du har samtykket til det benyttes kjøpshistorikk og adferdsmønster
        til å gi anbefalinger og reklame spesielt tilpasset deg som bruker.
        Grunnlaget for denne behandlingen er personvernforordningen Art 6 (a).
        Du kan når som helst trekke tilbake ditt samtykke til slik bruk.

        Vi lagrer de personopplysningene du har avgitt på våre nettsider. Vi
        bruker informasjonskapsler/cookies på våre nettsider for å gi deg som
        besøker siden best kundeopplevelse og service. Lov om elektronisk
        kommunikasjon krever at vi informerer våre besøkende om bruk av
        informasjonskapsler (cookies). Vi vil ikke dele, selge, overføre eller
        på annen måte utlevere personopplysninger til andre, med mindre vi er
        rettslig forpliktet til det. Opplysninger vi etter bokføringsloven er
        forpliktet til å bevare vil lagres i inntil 5 år, i henhold til lovens
        krav.

        Vi behandler dine personopplysninger i henhold til
        personopplysningsloven og gjeldende forskrifter. Det gjøres oppmerksom
        på at du kan kreve innsyn i og flytting av egne personopplysninger,
        samt kreve retting eller sletting av opplysninger. Det kan klages til
        Datatilsynet på behandling i strid med reglene.
      </p>
    </div>
  </div>
</div>
