import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingComponent } from './components/pages/booking/booking.component';
import { ChangeComponent } from './components/pages/change/change.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { JitsiComponent } from './components/pages/jitsi/jitsi.component';
import { PaymentMethodPickerComponent } from './components/pages/payment-method-picker/payment-method-picker.component';
import { PaymentRedirectComponent } from './components/pages/payment-redirect/payment-redirect.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { SuccessComponent } from './components/pages/success/success.component';

const routes: Routes = [
  { path: '', redirectTo: '/booking', pathMatch: 'full' },
  { path: 'booking', component: BookingComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'payment-redirect', component: PaymentRedirectComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'change', component: ChangeComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'jitsi', component: JitsiComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'payment-method-picker', component: PaymentMethodPickerComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
