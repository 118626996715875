import { PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js';
import { defer, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export function onPaymentMethodHook(
  event: PaymentRequestPaymentMethodEvent,
  stripe: Stripe,
  clientSecret: string
): Observable<boolean> {
  return defer(() =>
    stripe.confirmCardPayment(
      clientSecret,
      { payment_method: event?.paymentMethod?.id },
      { handleActions: false }
    )
  ).pipe(
    switchMap((data) => {
      if (data.error) {
        event.complete('fail');
        return of(false);
      }

      event.complete('success');
      if (data.paymentIntent?.status === 'requires_action') {
        return defer(() => stripe.confirmCardPayment(clientSecret)).pipe(
          map((confirmData) => !confirmData.error)
        );
      } else {
        return of(true);
      }
    }),
    tap((completed) => {
      if (!completed) {
        event.complete('fail');
      }
    })
  );
}
