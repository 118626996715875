import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // this.authenticationService.logout();
        // }

        // if (err.hasOwnProperty('error') === false) {
        //   return throwError(err.statusText);
        // }
        //
        // if (
        //   err.error.hasOwnProperty('message') === false &&
        //   err.error.hasOwnProperty('error') === false
        // ) {
        //   return throwError(err.error);
        // }
        //
        // const error = err.error.message || err.error.error;

        if (err.status === 200) {
          return throwError(err.error.text);
        }

        const error = this.getErrorMessage(err.error);
        return throwError(error);
      }),
    );
  }

  private getErrorMessage(err) {
    if (typeof err !== 'object') {
      return err;
    }

    for (let prop in err) {
      if (err.hasOwnProperty(prop)) {
        return this.getErrorMessage(err[prop]);
      }
    }
    return 'Something went wrong';
  }
}
