import {UserType} from '../../../common/services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiObject, OrderByChoice, OrderDirection, OrderingChoice, UserRole} from '../../../common/models/common.models';

export class UIHelper {
  private static fb = new FormBuilder();

  public static objToApi: ApiObject = {
    offset: 0,
    limit: 25,
    user: 31,
    ordering: OrderingChoice.Timeslot,
    code: '',
    date: '',
    search: '',
    order_by: '',
    order: OrderDirection.Asc,
    start_date: '',
    end_date: ''
  };

  public static hasPermission(url, userType: UserType): boolean {
    if (url === 'dashboard/doctors' && userType === UserType.Nurse) {
      return false;
    } else {
      return true;
    }
  }

  public static getMonthName(date) {
    const months = [
      'januar',
      'februar',
      'mars',
      'april',
      'mai',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'desember'
    ];
    const monthIndex = date.getMonth()
    return months[monthIndex];
  }

  public static getDayName(date) {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ]
    return days[date.getDay()]
  }

  public static createItem(): FormGroup {
    return this.fb.group({
      id: [null, Validators.required],
      price: [0, Validators.required],
      appointment_duration: [0, Validators.required],
    });
  }

  public static createItems(count: number): FormGroup[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(this.createItem());
    }
    return arr;
  }

  public static userHasRole(user, role = UserRole.Admin) {
    return user && user.role === role;
  }

  public static cannotModifyAppointment(user, appointment) {
    return user && appointment && this.userHasRole(user, UserRole.Doctor) && user.id !== appointment.timeslots[0].owner.id;
  }

  public static processErrorOnForms(err): string {
    return err;
  }

  public static formUrlBasedOnApiObject(url: string, obj: ApiObject): string {
    let addedParam = false;
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] === undefined || obj[key] === '' || key === 'user' || key === 'order') {
        return acc;
      }
      if (addedParam === false) {
        addedParam = true;
        return `${acc}?${key}=${obj[key]}`;
      }
      return `${acc}&${key}=${obj[key]}`;
    }, url);
  }
}
