<div class="container checkout-container my-5 py-5">
  <div
    class="row"
    *ngIf="!loadingPayment"
  >
    <div class="col-md-5 col-12">
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="checkout-container__back d-inline-block"
          (click)="back()"
        >
          <span class="iconify mr-2" data-icon="ant-design:arrow-left-outlined"></span>
          <span>Tilbake</span>
        </div>

        <div class="checkout-container__step d-inline-block">
          Steg {{ currentStep + 1 }} / 3
        </div>
      </div>
      <my-auth-form
        *ngIf="!loadingForm && currentStep === 1"
        [type]="currentFormType"
        [email]="email"
        [authFormGroup]="currentFormGroup"
        (formSubmitted)="formSubmitted($event)"
        (resetPassword)="resetPassword($event)"
      ></my-auth-form>

      <div
        class="text-center"
        *ngIf="loadingForm"
      >
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div
        class="final-step-container my-5"
        *ngIf="currentStep === 2"
      >
        <input
          type="hidden"
          [value]="appointment.price"
          name="amount"
        />
        <input
          type="hidden"
          value="do_tidypay_payment"
          name="action"
        />

        <div class="user-info-container">
          <h5>Du har logget deg på</h5>
        </div>

        <!-- <div class="accept-terms-wrapper" *ngIf="!acceptedTerms">
          <label class="accept-terms d-flex align-items-center my-4">
            <input
              class="mr-2"
              type="checkbox"
              name="accept_terms"
              [(ngModel)]="acceptedTerms"
            />
            Ved å klikke på knappen godtar du &nbsp; <a href="https://helseprivat.no/betingelser/" target="_blank">
            vilkårene våre</a>
          </label>
        </div> -->
        <!--                            <p class="accept-terms">Ved å klikke på knappen godtar du vilkårene våre</p>-->

        <div class="checkout-buttons-container">
          <button
            class="form-btn main-button border-0"
            type="submit"
            (click)="dibsPayment()"
          >
            Gå til betaling
          </button>
        </div>
      </div>
    </div>

    <div class="offset-md-1"></div>

    <div class="col-md-6 col-12">
      <my-selection-summary
        [specialist]="appointment.specialist"
        [category]="appointment.category"
        [date]="displaySlot(appointment.timeslot, 'MMM DD, YYYY')"
        [time]="displaySlot(appointment.timeslot)"
        [price]="appointment.price"
        [showBtn]="false"
        [canSelectCategory]="false"
      ></my-selection-summary>
    </div>
  </div>

  <div
    class="text-center"
    *ngIf="loadingPayment"
  >
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
