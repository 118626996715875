export interface PageQueryParams {
  type: MobilePaymentTypes;
  title: string;
  payByCardLabel: string;
  amount: number;
  subId: number;
  date: string;
  token: string;
}

export enum MobilePaymentTypes {
  SUBSCRIPTION = 'subscription',
  APPOINTMENT = 'appointment',
}
