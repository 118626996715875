// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  base_url: 'https://185.7.62.218/',
  // base_url: 'https://hjemmeliv.helseprivat.no/',
  production: false,
  base_route: 'rest/appointments/guest',
  guest_secret:
    'i5LoqngbjwToTpoIinXxUaSdyed5gwgTdpix9g94oHxsBR4JpFfLPX1cL++afmZIJ+ke+YYuZmi8XJ4PtqFx3g==',
  // guest_secret: '8L18600GBhyLUYSAqXBFp49fxxokrt9dC5q54DEmCNIT6setY4k/GIiyfohitqvI6mEQtTY6gyZJOwO2CDqOyw==',
  // dibs_checkout_key: '60d3e6cab93548ae83bfae3a3817b36e'
  dibs_checkout_key: 'cc5694cfbb6f49e5b268d25025649b32',
  dibs_script: 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1',
  stripe: {
    merchantName: 'Preact AS',
    priceId: 'price_1Lp8SWDh5YefepehrRduDxWx',
    publishableKey: 'pk_test_vyfO7XKzXkAUvCXgzv97k2Lw00h6r1h0FR',
  },
  // dibs_checkout_key: 'cc5694cfbb6f49e5b268d25025649b32'
  // dibs_checkout_key: '02e6f32939494017bdc5743193b39882'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
