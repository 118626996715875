<nav>
  <div class="nav-logo">
    <div id="hs_cos_wrapper_site_logo"
         class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-logo" style=""
         data-hs-cos-general-type="widget" data-hs-cos-type="module">

      <span id="hs_cos_wrapper_site_logo_hs_logo_widget"
            class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo" style=""
            data-hs-cos-general-type="widget" data-hs-cos-type="logo"><a href="//www.preact.no?hsLang=no"
                                                                         id="hs-link-site_logo_hs_logo_widget"
                                                                         style="border-width:0px;border:0px;"><img
        src="https://www.preact.no/hubfs/preact_logo_pos_rgb1.svg" class="hs-image-widget "
        style="width:400px;border-width:0px;border:0px;" width="400" alt="preact_logo_pos_rgb1"
        title="preact_logo_pos_rgb1"></a></span>
    </div>
  </div>

  <div class="nav-content">
    <div class="nav-cta nav-cta-mobile"></div>
    <div class="nav-navigation">
      <div id="hs_cos_wrapper_navigation" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
           style="" data-hs-cos-general-type="widget" data-hs-cos-type="module">
        <div id="nav" aria-label="Navigation" class="navigation" style="justify-content: ">
          <ul>
            <li class="">
              <a href="https://www.preact.no/behandlingstilbud?hsLang=no">
                <span>Dette hjelper vi deg med</span>
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/helsemedlemsskap?hsLang=no">
                <span>Bli medlem</span>
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/sporsm%C3%A5l-og-svar?hsLang=no">
                <span>Spørsmål og svar</span>
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/om-oss?hsLang=no">
                <span>Om oss</span>
              </a>
            </li>
            <li class="">
              <a href="https://www.preact.no/kontakt_oss?hsLang=no">
                <span>Kontakt oss</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nav-cta nav-cta-desktop">
    </div>
  </div>
  <div class="nav-overlay"></div>
</nav>
