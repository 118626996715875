import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import {
  PaymentLongpollingResponse,
  PaymentStatus,
} from '../../../common/models/payment.model';
import { PaymentsService } from '../../../common/services/payments.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  dibsInstance: any;

  interval: any;

  intervalSubject$ = new Subject();
  isPaymentSuccessful: Subscription;

  constructor(
    private paymentsService: PaymentsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // if (window['webkit']?.messageHandlers?.cordova_iab_postMessage) {
    // const obj = {
    //   success: true,
    // };
    // window['webkit']?.messageHandlers?.cordova_iab_postMessage(
    //   JSON.stringify(obj)
    // );
    // }

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params) {
        if (params['mobile']) {
          this.paymentsService.isMobilePayment = true;
        }

        if (params['token']) {
          localStorage.setItem('token', params['token']);
        }

        this.paymentsService.paymentId =
          params['paymentId'] ?? this.paymentsService.paymentId;
        this.paymentsService.amount =
          params['amount'] ?? this.paymentsService.amount;
        this.paymentsService.internalPaymentId =
          params['internalPaymentId'] ?? this.paymentsService.internalPaymentId;
      }

      this.dibsInstance = (window as any).Dibs;
      const checkoutOptions = {
        paymentId: this.paymentsService.paymentId,
        checkoutKey: environment.dibs_checkout_key,
        language: 'nb-NO',
      };

      let checkout = new this.dibsInstance.Checkout(checkoutOptions);

      this.intervalSubject$.asObservable().subscribe(() => {
        if (this.isPaymentSuccessful) {
          this.isPaymentSuccessful.unsubscribe();
        }

        this.isPaymentSuccessful = this.paymentsService
          .isPaymentSuccessful()
          .subscribe((data: PaymentLongpollingResponse) => {
            if (data.status === PaymentStatus.CHECKOUT_COMPLETED) {
              this.router.navigateByUrl('/payment-redirect');
            }
          });
      });

      this.interval = setInterval(() => {
        this.intervalSubject$.next();
      }, 3000);
      // console.log('CHECKOUT KEY', checkoutOptions, checkout);
      checkout.on('payment-completed', (response) => {
        this.router.navigateByUrl('/payment-redirect');
      });
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.intervalSubject$.unsubscribe();
    if (this.isPaymentSuccessful) {
      this.isPaymentSuccessful.unsubscribe();
    }
  }
}
