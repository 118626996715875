import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpHeaderResponse,
  HttpHeaders,
} from '@angular/common/http';

import {map} from 'rxjs/operators';

import {environment} from 'src/environments/environment';
import {OptionsProvider as auth} from './options-provider';
import {Appointment} from "../models/appointment.model";

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  private baseUrl = environment.base_url;
  private baseRoute = environment.base_route;

  newAppointment = {
    patient_id: 0,
    reminder: true,
    reminder_interval: 600,
    type: 'normal',
    timeslot_ids: [],
    payment_details: undefined,
    category_setting_id: 0
  };

  appointmentId: number;
  appointment: Appointment;

  uuid: string;

  constructor(private http: HttpClient) {
  }

  /**
   * @desc Lock a new timeslot
   * @param ids
   */
  lockTimeslot(ids: number[]) {
    return this.http
      .post(
        `${this.baseUrl}${this.baseRoute}/lock-timeslot`,
        { timeslot_ids: ids, uuid: this.uuid },
        auth.guestOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * @desc Unlock the previously selected timeslot
   * @param ids
   */
  unlockTimeslot(ids: number[]) {
    return this.http
      .post(
        `${this.baseUrl}${this.baseRoute}/unlock-timeslot`,
        { timeslot_ids: ids, uuid: this.uuid },
        auth.guestOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * @desc Create a new appointment
   * @param paymentDetails
   */
  createAppointment(paymentDetails) {
    const body = {
      'patient_id': parseInt(localStorage.getItem('patient_id')),
      'reminder': true,
      'reminder_interval': 600,
      'state': 'autoapproved',
      'type': 'normal',
      'timeslot_ids': JSON.parse(localStorage.getItem('timeslot_ids')),
      'payment_details': paymentDetails,
      'category_setting_id': localStorage.getItem('category_setting_id'),
      'uuid': this.uuid
    };

    return this.http
      .post(
        `${this.baseUrl}rest/appointments/schedules`,
        body,
        auth.options
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getCountries() {
    return this.http
      .get(
        `${this.baseUrl}rest/appointments/guest/available-countries/`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * @desc Get appointment details by id
   */
  getAppointmentById() {
    return this.http
      .get(
        `${this.baseUrl}rest/appointments/schedules/${this.appointmentId}`,
        // `${this.baseUrl}rest/appointments/schedules/390`,
        auth.options
      )
      .pipe(
        map((res: any) => {
          if (res.id) {
            this.appointment = {
              id: res.id,
              doctorName: `Dr. ${res.timeslots[0].owner.user.first_name} ${res.timeslots[0].owner.user.last_name}`,
              appointmentDate: new Date(res.timeslots[0].start).toLocaleDateString('no-NO'),
              appointmentTime: new Date(res.timeslots[0].start).toLocaleTimeString('no-NO'),
              code: res.code
            };
          }
          return res;
        })
      );
  }

  /**
   * @desc Get appointment details by cancel / reschedule token
   * @param token
   */
  getAppointmentByToken(token: string) {
    return this.http
      .get(
        `${this.baseUrl}rest/appointments/guest/appointment-exists?token=${token}`,
        auth.guestOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * @desc Reschedule appointment
   */
  reschedule(token: string, ids: number[]) {
    return this.http
      .post(
        `${this.baseUrl}${this.baseRoute}/reschedule-appointment`,
        { token: token, timeslot_ids: ids },
        auth.guestOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * @desc Cancel appointment
   */
  cancel(token: string) {
    return this.http
      .get(
        `${this.baseUrl}${this.baseRoute}/cancel-appointment?token=${token}`,
        auth.guestOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * @desc Generate a random uuid
   */
  generateUuid() {
    this.uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
