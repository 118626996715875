import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Payment, PaymentLongpollingResponse } from '../models/payment.model';
import { OptionsProvider as auth } from './options-provider';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private baseUrl = environment.base_url;
  private baseRoute = environment.base_route;

  paymentId: string;
  internalPaymentId: string;
  chargeId: string;
  amount: number;

  isMobilePayment: boolean;

  constructor(private http: HttpClient) {}

  createPayment(paymentInfo): Observable<Payment> {
    const body = {
      ...paymentInfo,
      amount: paymentInfo.amount * 100,
    };

    return this.http
      .post(
        `${this.baseUrl}rest/appointments/guest/create-schedule-payment/nets/`,
        body,
        auth.options
      )
      .pipe(
        map((res: Payment) => {
          this.paymentId = res.paymentId;
          this.internalPaymentId = res.internalPaymentId.toString();
          this.amount = body.amount;
          return res;
        })
      );
  }

  createPaymentSub(id: number): Observable<Payment> {
    return this.http
      .post(
        `${this.baseUrl}rest/appointments/subscriptions/nets/${id}/`,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['token']}`,
            'Accept-Language': 'en',
          }),
        }
      )
      .pipe(map((res: Payment) => res));
  }

  chargePayment() {
    return this.http
      .put(
        `${this.baseUrl}rest/appointments/guest/payment/${this.internalPaymentId}/`,
        { action: 'charge' },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['token']}`,
            'Accept-Language': 'en',
          }),
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  refundPayment() {
    return this.http
      .put(
        `${this.baseUrl}rest/appointments/guest/payment/${this.internalPaymentId}/`,
        { action: 'refund' },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['token']}`,
            'Accept-Language': 'en',
          }),
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  isPaymentSuccessful(): Observable<PaymentLongpollingResponse> {
    return this.http
      .get(
        `${this.baseUrl}rest/appointments/guest/payment/${this.internalPaymentId}/`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['token']}`,
            'Accept-Language': 'en',
          }),
        }
      )
      .pipe(
        map((res: PaymentLongpollingResponse) => {
          return res;
        })
      );
  }

  createSubApplePay(id: number): Observable<Payment> {
    return this.http
      .post(
        `${this.baseUrl}rest/appointments/subscriptions/stripe/${id}/`,
        {
          priceId: environment.stripe.priceId,
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['token']}`,
            'Accept-Language': 'en',
          }),
        }
      )
      .pipe(map((res: Payment) => res));
  }

  createSinglePaymentApplePay(body: {
    amount: number;
    date: string;
  }): Observable<Payment> {
    return this.http
      .post(
        `${this.baseUrl}rest/appointments/guest/create-schedule-payment/stripe/`,
        body,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage['token']}`,
            'Accept-Language': 'en',
          }),
        }
      )
      .pipe(map((res: Payment) => res));
  }
}
